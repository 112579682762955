import { ElNotification } from 'element-plus/es'
import { ValidError } from './validator'
export async function saveInvoke(call: () => Promise<boolean>, title: () => string): Promise<void> {
  try {
    const resp = await call()
    if ((resp ?? false) === false) {
      return Promise.reject(new Error('服务拒绝保存:' + title()))
    }
    ElNotification({ message: '已保存:' + title(), type: 'success', duration: 3000 })
  } catch (e: any) {
    if (e instanceof ValidError) {
      return Promise.reject(e)
    }
    const msg = e?.message ?? e
    return Promise.reject(new Error(msg as string))
  }
}

export async function delInovke(call: () => Promise<boolean>, title: () => string): Promise<boolean> {
  try {
    const resp = await call()
    if ((resp ?? false) === false) {
      return Promise.reject(new Error('服务拒绝删除:' + title()))
    }
    ElNotification({ message: '成功删除:' + title(), type: 'success', duration: 3000 })
    return resp
  } catch (e) {
    let msg = e
    if (e instanceof Error) {
      msg = e.message
    }
    ElNotification({ message: '删除:' + title() + '失败,' + msg, type: 'error', duration: 5000 })
    return Promise.reject(msg)
  }
}
