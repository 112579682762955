
import md5 from 'blueimp-md5'
export function dateFormat(date?: any, format?: string): string {
  if (date === undefined && format === undefined) {
    // 无参数
    date = new Date()
    format = 'yyyy-MM-dd HH:mm:ss'
  } else if (typeof (date) === 'string') {
    // 无日期
    format = date
    date = new Date()
  } else if (format === undefined) {
    // 无格式化参数
    format = 'yyyy-MM-dd HH:mm:ss'
  }
  // 没有分隔符的特殊处理
  const hours = date.getHours()
  const map: any = {
    y: date.getFullYear() + '', // 年份
    M: date.getMonth() + 1 + '', // 月份
    d: date.getDate() + '', // 日
    H: hours + '', // 小时 24
    h: (hours > 12 ? hours - 12 : hours) + '', // 小时 24
    m: date.getMinutes() + '', // 分
    s: date.getSeconds() + '', // 秒
    q: Math.floor((date.getMonth() + 3) / 3) + '', // 季度
    f: date.getMilliseconds() + '' // 毫秒
  }

  const reg = 'yMdHhmsqf'
  let all = ''
  let str = ''
  for (let i = 0, n = 0; i < reg.length; i++) {
    n = format.indexOf(reg[i])
    if (n < 0) { continue }
    all = ''
    for (; n < format.length; n++) {
      if (format[n] !== reg[i]) {
        break
      }
      all += reg[i]
    }
    const mapStr: any = map[reg[i]]
    if (all.length > 0) {
      if (all.length === mapStr.length) {
        str = mapStr
      } else if (all.length > mapStr.length) {
        if (reg[i] === '"f') {
          str = mapStr + charString('0', all.length - mapStr.length)
        } else {
          str = charString('0', all.length - mapStr.length) + mapStr
        }
      } else {
        switch (reg[i]) {
          case 'y': str = mapStr.substr(mapStr.length - all.length); break
          case 'f': str = mapStr.substr(0, all.length); break
          default: str = mapStr; break
        }
      }
      format = format.replace(all, str)
    }
  }
  return format
}

function charString(char: string, refCount: number): string {
  let count = refCount
  let str: string = ''
  while (count--) {
    str += char
  }
  return str
}
export function getQuery(url: string, name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = url.substring(1).match(reg)
  return r == null ? null : r[2]
}
export function getLocation(url: string) {
  const index = url.indexOf('?')
  if (index >= 0) {
    return url.substring(0, index)
  } else {
    return url
  }
}

export function adaptPassword(password: string) {
  return md5(password)
}
export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let fileResult: string | null | ArrayBuffer = ''
    reader.readAsDataURL(file)
    // 开始转
    reader.onload = () => {
      fileResult = reader.result
    }
    // 转 失败
    reader.onerror = (error) => {
      reject(error)
    }
    // 转 结束  咱就 resolve 出去
    reader.onloadend = () => {
      resolve(fileResult)
    }
  })
}
export function getFileName(o) {
  if (o === undefined) {
    return undefined
  }
  let pos = o.lastIndexOf('\\')
  if (pos < 0) {
    pos = o.lastIndexOf('/')
  }
  if (pos < 0) {
    return undefined
  }
  return o.substring(pos + 1)
}
export function getSuffix(file: string): string | undefined {
  const filename = file
  const index1 = filename.lastIndexOf('.')
  if (index1 < 0) {
    return undefined
  }
  const index2 = filename.length
  const type = filename.substring(index1, index2)
  return type
}

interface TypeObj { [anyKey: string]: any }

/**
 * @param copy 深拷贝
 * @param _object 如果不传返回为空对象 必须是js的{}对象
 * @param _obj 可选 返回传入的@param _object 必须是js的{}对象,
 */
export const copyObj = (_object: TypeObj, _obj: TypeObj = {}): TypeObj => {
  if (!(Object.prototype === Object.getPrototypeOf(_object))) {
    return new Error('传入参数***_object***类型错误')
  }
  for (const key in _object) {
    if (Object.prototype === Object.getPrototypeOf(_object[key])) {
      _obj[key] = copyObj(_object[key])
    } else {
      _obj[key] = _object[key]
    }
  }
  return _obj
}
