import 'reflect-metadata'

export enum IFromType {
  Slot,
  Ohter,
  Input,
  AreaText,
  Switch,
  Number,
}

export interface IFromItem {
  field: string
  sort: number
  type: IFromType
  title: string
}

export class FromContentItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  value: unknown
  constructor(fieldName: string, v: object) {
    this.field = fieldName
    this.sort = 0
    this.title = ''
    this.value = v
    this.type = IFromType.Ohter
  }
}

export class FromSlotItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.title = title
    this.sort = index
    this.type = IFromType.Slot
  }
}
export class FromInputItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  maxlength?: number
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.title = title
    this.sort = index
    this.type = IFromType.Input
  }
}

export class FromAreaTextItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  min: number = 2
  max: number = 6
  maxlength?: number
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.title = title
    this.sort = index
    this.type = IFromType.AreaText
  }
}
export class FromNumberItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  min: number = 2
  max: number = 6
  step: number = 1
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.title = title
    this.sort = index
    this.type = IFromType.Number
  }
}

export class FromSwitchItem implements IFromItem {
  field: string
  sort: number
  title: string
  type: IFromType
  onColor?: string
  offColor?: string
  onIcon?: string | Comment
  offIcon?: string | Comment
  onText?: string
  offText?: string
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.title = title
    this.sort = index
    this.type = IFromType.Switch
  }
}
const fromKey = 'InputFromRule'

export const fromSlot = (info?: { title?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(fromKey, target)) {
      Reflect.defineMetadata(fromKey, {}, target)
    }
    const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    rules[key.toString()] = new FromSlotItem(key.toString(), info?.title ?? key.toString(), index + 1)
    Reflect.defineMetadata(fromKey, rules, target)
  }
}

export const fromInput = (info?: { title?: string | undefined, maxlength?: number } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(fromKey, target)) {
      Reflect.defineMetadata(fromKey, {}, target)
    }
    const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    const tmp = new FromInputItem(key.toString(), info?.title ?? key.toString(), index + 1)
    tmp.maxlength = info?.maxlength
    rules[key.toString()] = tmp
    Reflect.defineMetadata(fromKey, rules, target)
  }
}

export const areaInput = (info?: { title?: string | undefined, maxlength?: number, min?: number | undefined, max?: number | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(fromKey, target)) {
      Reflect.defineMetadata(fromKey, {}, target)
    }
    const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    const tmp = new FromAreaTextItem(key.toString(), info?.title ?? key.toString(), index + 1)
    tmp.min = info?.min ?? 2
    tmp.max = info?.max ?? 6
    tmp.maxlength = info?.maxlength
    rules[key.toString()] = tmp
    Reflect.defineMetadata(fromKey, rules, target)
  }
}

export const fromNumber = (info?: { title?: string | undefined, step?: number, min?: number | undefined, max?: number | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(fromKey, target)) {
      Reflect.defineMetadata(fromKey, {}, target)
    }
    const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    const tmp = new FromNumberItem(key.toString(), info?.title ?? key.toString(), index + 1)
    tmp.min = info?.min ?? 2
    tmp.max = info?.max ?? 6
    tmp.step = info?.step ?? 1
    rules[key.toString()] = tmp
    Reflect.defineMetadata(fromKey, rules, target)
  }
}

export const fromSwitch = (info?: { title?: string | undefined } | undefined, onInfo?: { color?: string | undefined, text?: string | undefined, icon?: string | any | undefined }, offInfo?: { color?: string | undefined, text?: string | undefined, icon?: string | any | undefined }): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(fromKey, target)) {
      Reflect.defineMetadata(fromKey, {}, target)
    }
    const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    const tmp = new FromSwitchItem(key.toString(), info?.title ?? key.toString(), index + 1)
    tmp.onColor = onInfo?.color
    tmp.onIcon = onInfo?.icon
    tmp.onText = onInfo?.text
    tmp.offColor = offInfo?.color
    tmp.offIcon = offInfo?.icon
    tmp.offText = offInfo?.text
    rules[key.toString()] = tmp
    Reflect.defineMetadata(fromKey, rules, target)
  }
}

export interface FromRenderInfo {
  labelWidth?: number
  size?: 'large' | 'default' | 'small'
  labelPosition: 'left' | 'right'
  fileds: Array<IFromItem>
}
export function RenderQueryContent(o: any | Array<any>): FromRenderInfo | undefined {
  if (o instanceof Array) {
    if (o != null && o.length > 0) {
      o = o[0]
    } else {
      return undefined
    }
  }
  if (!Reflect.hasMetadata(fromKey, o)) {
    return undefined
  }
  const rules: { [index: string]: IFromItem } = Reflect.getMetadata(fromKey, o)
  if (rules === undefined) {
    return undefined
  }
  const ret: FromRenderInfo = {
    labelWidth: undefined,
    size: 'default',
    labelPosition: 'left',
    fileds: []
  }
  for (const key in rules) {
    const rule = rules[key]
    if (rule instanceof FromContentItem) {
      if (rule.field === 'labelWidth') {
        ret.labelWidth = rule.value as number
      } else if (rule.field === 'size') {
        ret.size = rule.value as 'large' | 'default' | 'small'
      } else if (rule.field === 'labelPosition') {
        ret.labelPosition = rule.value as 'left' | 'right'
      }
    } else {
      ret.fileds.push(rule)
    }
  }
  ret.fileds.sort((a, b) => a.sort - b.sort)
  return ret
}
