import { createRouter, createWebHistory } from 'vue-router'
import { routerUtils, changeRoute as inputRouter } from '@util/utils/RouterUtils'
import { IAppInfo } from '@util/storage/routerCache'
import map from './routerMap'
routerUtils.use(map)
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: routerUtils.get()
})
export function changeRoute(appInfo: IAppInfo) {
  if (appInfo !== undefined && appInfo.menus !== undefined) {
    inputRouter(router, appInfo.menus.map(s => {
      return {
        name: s.name,
        icon: s.icon,
        title: s.title,
        desc: s.desc
      }
    }))
  }
}
export default router
