import { createStore, Store } from 'vuex'
import { tokenModule } from '@util/storage/localToken'
import { LoginTokenResponse, UserToken } from '@util/models/TokenModels'
import { routerModule } from '@util/storage/routerCache'
import { InjectionKey } from 'vue'
interface IRootState {
  appDesc: string | undefined
}
export const key: InjectionKey<Store<IRootState>> = Symbol('storage key')
export const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tokenModule,
    routerModule
  }
})
export function hasLogin(): boolean {
  return store.getters['tokenModule/hasToken']
}
export function asyncToken(): Promise<boolean> {
  return store.getters['tokenModule/asyncToken']
}
export function getToken(): Promise<UserToken> {
  return store.getters['tokenModule/getToken']
}
export function hashRemember(): boolean | undefined {
  return store.getters['tokenModule/hashRemember']
}
export function getRefreshToken(): string | undefined {
  return store.getters['tokenModule/getRefreshToken']
}
export function setTokenInfo(rep: LoginTokenResponse) {
  store.commit('tokenModule/updateToken', rep)
}
export function getMenus() {
  return store.getters['routerModule/getMenu']
}
export function getMenuName() {
  return store.getters['routerModule/getMenuName']
}
export async function clearToken() {
  store.commit('tokenModule/clearToken')
}
export async function clearHttpToken() {
  store.commit('tokenModule/clearHttpToken')
}
export async function setCollapse(collapse: boolean) {
  store.commit('routerModule/setCollapse', collapse)
}
export function getCollapse() {
  return store.getters['routerModule/getCollapse']
}
