/* eslint-disable space-before-function-paren */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import md5 from 'blueimp-md5'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import { ref, Ref, UnwrapRef } from 'vue'
import { BaseResponse, ServerError, TokenExpiredError } from '@util/models/HttpClientModels'
import { PageResponse, PageRequest, ExecuteResponse, checkPageResponse } from '@util/models/ServerBaseEntity'
import { ValidIdRequest } from '@/util/utils/validator'
import { ElMessageBox } from 'element-plus/es'
import { getError } from './HttpClientError'
const HttpLoginCode = 6

export declare type OperationType = 'insert' | 'edit' | 'remove'
export class ISaveEditRequest<TGet, TSave, TEdit> {
  private getSave?: () => TSave
  private getEdit?: () => TEdit
  private getEntity: () => TGet
  private postEdit?: (o: TEdit) => Promise<boolean>
  private postSave?: (o: TSave) => Promise<boolean>
  private postGet?: (o: ValidIdRequest) => Promise<TGet>
  private postDel?: (o: ValidIdRequest) => Promise<boolean>

  constructor(
    getCall: {
      entity: () => TGet,
      save?: () => TSave,
      edit?: () => TEdit
    },
    postCall?: {
      entity?: (o: ValidIdRequest) => Promise<TGet>,
      save?: (o: TSave) => Promise<boolean>,
      edit?: (o: TEdit) => Promise<boolean>
      del?: (o: ValidIdRequest) => Promise<boolean>
    }) {
    this.getSave = getCall.save
    this.getEdit = getCall.edit
    this.getEntity = getCall.entity
    this.postEdit = postCall?.edit
    this.postSave = postCall?.save
    this.postGet = postCall?.entity
    this.postDel = postCall?.del
  }

  private copyObj<T1, T2>(src: T1, target: T2): T2 {
    const val = JSON.parse(JSON.stringify(src))
    const t: any = target
    for (const s in target) {
      if (val[s] !== undefined) {
        t[s] = val[s]
      }
    }
    return target
  }

  async edit(o: TGet): Promise<boolean> {
    if (this.getEdit === undefined || this.postEdit === undefined) {
      throw new Error('get edit invoke fail!')
    }
    const tmp = this.copyObj<TGet, TEdit>(o, this.getEdit())
    const resp = await this.postEdit(tmp)
    return resp
  }

  async save(o: TGet): Promise<boolean> {
    if (this.getSave === undefined || this.postSave === undefined) {
      throw new Error('get save invoke fail!')
    }
    const tmp = this.copyObj<TGet, TSave>(o, this.getSave())
    const resp = await this.postSave(tmp)
    return resp
  }

  // tslint:disable-next-line: ban-types
  async del(o: ValidIdRequest | Number): Promise<boolean> {
    if (this.postDel === undefined) {
      throw new Error('get del invoke fail!')
    }
    const tmp = new ValidIdRequest()
    if (o instanceof ValidIdRequest) {
      tmp.id = o.id
    } else if (o instanceof Number) {
      tmp.id = o.valueOf()
    } else {
      tmp.id = (o as any)?.id ?? 0
    }
    const resp = this.postDel(tmp)
    return resp
  }

  // tslint:disable-next-line: ban-types
  async get(o?: ValidIdRequest | Number) {
    if (o === undefined) {
      return this.getEntity()
    } else {
      if (this.postGet === undefined) {
        throw new Error('get post invoke fail!')
      }
      const tmp = new ValidIdRequest()
      if (o instanceof ValidIdRequest) {
        tmp.id = o.id
      } else if (o instanceof Number) {
        tmp.id = o.valueOf()
      } else {
        tmp.id = (o as any)?.id ?? 0
      }
      try {
        const resp = await this.postGet(tmp)
        return resp
      } catch (e) {
        let msg = e
        if (e instanceof Error) {
          msg = e.message
        }
        ElMessageBox({
          type: 'error',
          showClose: true,
          message: msg as string
        })
      }
    }
  }
}

export class ApiWinModel<TReq extends PageRequest, TResp> {
  loading: Ref<boolean>
  reqModel: Ref<UnwrapRef<TReq>>
  respModel: TResp
  respObj: Ref<PageResponse<TResp>>

  constructor(req: () => TReq, resp: () => TResp) {
    this.loading = ref(false)
    this.reqModel = ref<TReq>(req())
    this.respModel = resp()
    const page: PageResponse<TResp> = {
      list: [],
      page_count: 0,
      total_count: 0
    }
    const t = ref(page)
    this.respObj = ref(page) as Ref<PageResponse<TResp>>
  }
}

interface RefreshTokenRequest {
  // eslint-disable-next-line camelcase
  refresh_token: string
}
export async function refresh() {
  if (useStore === undefined) {
    return Promise.resolve(false)
  }
  const refreshToken = await useStore.getters['tokenModule/getRefreshToken']

  if (refreshToken === undefined || refreshToken == null) {
    throw new Error('refresh token not found')
  }

  const resp = await httpPost<RefreshTokenRequest, any>('/auth/login/refresh', {
    refresh_token: refreshToken
  }, false)

  await useStore.commit('tokenModule/updateToken', {
    access_token: resp.access_token,
    refresh_token: resp.refresh_token,
    user_name: resp.username,
    expired: resp.expired,
    remember: undefined
  })
  hasRequestRefreshToken = false
  return true
}
axios.defaults.timeout = 5000
let useStore: Store<any> | undefined
let useRouter: Router
let showError: ((str: string) => void) | undefined
let hasRequestRefreshToken: boolean = false
// let requestQueue: Array<any> = []

async function refreshToken(config: AxiosRequestConfig) {
  // requestQueue.push(() => {
  //   try {
  //     const data = JSON.parse(config.data)
  //     if (data.parameter !== undefined) {
  //       config.data = data.parameter
  //     }
  //   } catch {
  //     console.warn('convert parameter fail')
  //   }
  //   axios(config)
  // })
  if (!hasRequestRefreshToken) {
    hasRequestRefreshToken = true
    let isGetToken: boolean = false
    try {
      isGetToken = await refresh()
      hasRequestRefreshToken = false
    } catch (e) {
      isGetToken = false
    }
    if (!isGetToken) {
      let refUrl = '/'
      if (process.env.VUE_APP_REFLOGIN !== undefined && process.env.VUE_APP_REFLOGIN.trim() !== '') {
        refUrl = process.env.VUE_APP_REFLOGIN
      }
      if (useRouter !== undefined && refUrl !== undefined && refUrl !== '') {
        useRouter.replace(refUrl)
        // 强制刷新菜单
        useStore?.commit('routerModule/updateToken')
      } else {
        throw new Error('refresh token fail!')
      }
    }
    // requestQueue.forEach(cb => cb())
    // requestQueue = []
  }
}
export function initHttpClient(store: Store<any>, router: Router, showErrorInvoke: ((str: string) => void) | undefined) {
  useStore = store
  useRouter = router
  showError = showErrorInvoke
}
function uuid(): string {
  const random: (multiplier: number) => number = (multiplier: number) => {
    return Math.floor(Math.random() * multiplier)
  }

  const hexadecimal: (index: number) => string = (index: number) => {
    return ((index === 19) ? random(4) + 8 : random(16)).toString(16)
  }

  const nexttoken: (index: number) => string = (index: number) => {
    if (index === 8 || index === 13 || index === 18 || index === 23) {
      return '-'
    } else if (index === 14) {
      return '4'
    } else {
      return hexadecimal(index)
    }
  }
  const generate: () => string = () => {
    let uuid: string = ''

    while ((uuid.length) < 36) {
      uuid += nexttoken(uuid.length)
    }
    return uuid
  }
  return generate()
}
class RequestParameter<T> {
  parameter?: T
  constructor(param: T) {
    this.parameter = param
  }
}
function clearConfigUrl(config: AxiosRequestConfig) {
  // const baseUrl = useStore?.getters['routerModule/getHttpUrl']
  // if (baseUrl !== undefined) {
  //   config.url = config.url?.replace(baseUrl, '')
  // }
}
function adaptConfig(config: AxiosRequestConfig, token: string) {
  const baseUrl = useStore?.getters['routerModule/getHttpUrl']
  if (baseUrl === undefined) {
    throw new Error('baseurl not found!')
  }
  config.timeout = 30000
  let contentType: string | number | boolean | undefined
  let signatureData: string | undefined
  if (config.headers !== undefined) {
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded' || config.headers['Content-Type'] === 'multipart/form-data') {
      signatureData = '{}'
      contentType = 'multipart/form-data;boundary = ' + new Date().getTime()
    } else {
      contentType = config.headers['Content-Type']
    }
  }
  if (signatureData === undefined) {
    if (process.env.VUE_APP_PARAMETER !== undefined && process.env.VUE_APP_PARAMETER === 'true') {
      config.data = new RequestParameter(config.data)
      if (config.data.parameter === undefined) {
        config.data.parameter = {}
      }
    }
    config.data = JSON.stringify(config.data || {})
    if (config.data === '') {
      config.data = '{}'
    }
  }
  if (!token || token == null) {
    token = ''
  }
  let secret = ''
  if (process.env.VUE_APP_SECRET !== undefined && process.env.VUE_APP_SECRET.length > 0) {
    secret = process.env.VUE_APP_SECRET
  }
  let requestId: string = uuid()
  if (requestId !== undefined) {
    requestId = requestId.replaceAll('-', '')
  }
  const timeStamp = (new Date()).getTime() / 1000
  const signature = md5([requestId, timeStamp, signatureData ?? config.data, secret, token].join('&'))
  // console.log([requestId, timeStamp, config.data, secret, token].join('&'))
  //  console.log(signature)
  config.headers = {
    signature,
    'Content-Type': contentType ?? 'application/json',
    'request-id': requestId,
    'time-stamp': String(timeStamp),
    'access-token': token
  }
  // config.url = baseUrl + config.url
}
axios.interceptors.request.use(
  async config => {
    try {
      const token = await useStore?.getters['tokenModule/getToken']
      if (config.url !== undefined) {
        if (config.url.indexOf('/auth') === 0) {
          adaptConfig(config, token)
          return config
        }
        if (token === undefined || token === '') {
          refreshToken(config)
          return Promise.reject(new ReferenceError())
        }
        adaptConfig(config, token)
      } else {
        return Promise.reject(new Error('not found url'))
      }
    } catch (err) {
      if (config.url === '/auth/login/refresh') {
        adaptConfig(config, '')
        return config
      }
      if (err instanceof TokenExpiredError) {
        refreshToken(config)
        return Promise.reject(new ReferenceError())
      }
      return Promise.reject(err)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error instanceof ReferenceError) {
      throw error
    }
    if (error instanceof String) {
      return Promise.reject(error)
    }
    if (error.response) {
      switch (error.response.status) {
        case 401:
          clearConfigUrl(error.config)
          await refreshToken(error.config)
          return Promise.reject(new ReferenceError())
        case 404:
          throw new Error('not found url page')
      }
    }
    if (error.response === undefined || error.response.data === undefined) {
      throw new Error('get repsonse fail')
    } else {
      return Promise.reject(error.response.data)
    }
  })
async function axiosResponse<TResp>(response: AxiosResponse<BaseResponse<TResp>> | undefined, showFail?: boolean | undefined) {
  if (response == null || response === undefined) {
    return Promise.reject(new Error('request failed Error: Response Is Empty'))
  } else if (response.data == null) {
    return Promise.reject(new Error('request failed Error: Response Data Is Empty'))
  } else {
    if (response.status === 401) {
      clearConfigUrl(response.config)
      await refreshToken(response.config)
      return Promise.reject(new Error('request failed Error: Authentication'))
    } else {
      const httpResponse = response.data
      if (httpResponse.code !== 0) {
        if (httpResponse.code === HttpLoginCode) {
          clearConfigUrl(response.config)
          refreshToken(response.config)
        } if (httpResponse.code === 108) {
          clearConfigUrl(response.config)
          refreshToken(response.config)
        } else {
          if (showError !== undefined && showFail !== undefined && showFail) {
            showError(getError(response.data))
          } else {
            // console.error(response.data.message + ',Errpr: Code -> ' + response.data.code)
          }
          const err: ServerError = {
            name: response.data.code.toString(),
            code: response.data.code,
            message: getError(response.data)
          }
          return Promise.reject(err)
        }
      } else {
        if (httpResponse.data === undefined) {
          return Promise.reject(new Error('request failed Error: Response Is Empty'))
        }
        return Promise.resolve(httpResponse.data)
      }
    }
  }
}

export async function fetch<TReq, TResp>(url: string, params: TReq, showFail: boolean | undefined): Promise<TResp> {
  const response = await timeoutFetch(url, { params })
  const rep = await axiosResponse<TResp>(response, showFail)
  if (rep === undefined) {
    return Promise.reject(new Error('get response is undefined'))
  }
  return rep
}
function timeout() {
  return new Promise(resolve => {
    setTimeout(resolve, 500)
  })
}
async function timeoutFetch<TReq, TResp>(url: string, params: TReq) {
  try {
    return await axios.get(url, { params })
  } catch (error) {
    if (error instanceof ReferenceError) {
      await timeout()
      const resp = await timeoutFetch(url, params)
      return resp
    } else {
      throw error
    }
  }
}
async function timeoutPost<TReq, TResp>(url: string, data: TReq) {
  try {
    return await axios.post(url, data)
  } catch (error: any) {
    if (error instanceof ReferenceError) {
      await timeout()
      const resp = await timeoutPost(url, data)
      return resp
    } else {
      throw error
    }
  }
}
export async function httpPost<TReq, TResp>(url: string, data: TReq, showFail?: boolean | undefined): Promise<TResp> {
  try {
    const response = await timeoutPost(url, data)
    const rep = await axiosResponse<TResp>(response, showFail)
    if (rep === undefined) {
      return Promise.reject(new Error('get response is undefined'))
    }
    return rep
  } catch (error: any) {
    if (error.code !== undefined && error.message !== undefined) {
      const err: ServerError = {
        name: error.code.toString(),
        code: error.code,
        message: getError(error)
      }
      return Promise.reject(err)
    } else {
      throw error
    }
  }
}

export async function formData<TResp>(url: string, data: File, showFail?: boolean | undefined): Promise<TResp> {
  const param = new FormData()
  param.append('file', data)
  const response = await axios.post(url, param, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  const rep = await axiosResponse<TResp>(response, showFail)
  if (rep === undefined) {
    return Promise.reject(new Error('get response is undefined'))
  }
  return rep
}

export function getEmptyInvoke<T>(): Promise<T> {
  return Promise.reject(new Error('function not header'))
}

export class EmptyHttpError extends Error {
  constructor() {
    super('Method not implemented')
  }
}

export function execPostValid(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    valid()(target, key, descriptor)
    post(path)(target, key, descriptor)
    adapt<ExecuteResponse, boolean>(s => s?.success ?? false)(target, key, descriptor)
  }
}

export function execHidePostValid(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    valid()(target, key, descriptor)
    hidePost(path)(target, key, descriptor)
    adapt<ExecuteResponse, boolean>(s => s?.success ?? false)(target, key, descriptor)
  }
}

export function queryPost<TResp>(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    post(path)(target, key, descriptor)
    adapt<PageResponse<TResp>, PageResponse<TResp>>(s => checkPageResponse(s))(target, key, descriptor)
  }
}

export function queryPostValid<TResp>(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    valid()(target, key, descriptor)
    post(path)(target, key, descriptor)
    adapt<PageResponse<TResp>, PageResponse<TResp>>(s => checkPageResponse(s))(target, key, descriptor)
  }
}

export function getPost<TReq, TResp>(path: string, e: (resp: TReq) => TResp): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    post(path)(target, key, descriptor)
    adapt<TReq, TResp>(e)(target, key, descriptor)
  }
}

export function adaptPost<TReq>(path: string, e: (resp: TReq) => TReq): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    adapt<TReq, TReq>(e)(target, key, descriptor)
    post(path)(target, key, descriptor)
  }
}

export function adaptPostValid<TReq>(path: string, e: (resp: TReq) => TReq): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    valid()(target, key, descriptor)
    post(path)(target, key, descriptor)
    adapt<TReq, TReq>(e)(target, key, descriptor)
  }
}
export function adaptHidePostValid<TReq>(path: string, e: (resp: TReq) => TReq): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    valid()(target, key, descriptor)
    hidePost(path)(target, key, descriptor)
    adapt<TReq, TReq>(e)(target, key, descriptor)
  }
}

export function hidePost(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    const origin = descriptor.value
    descriptor.value = async (...args: any[]) => {
      const req: any | undefined = args.length > 0 ? args[0] : undefined
      try {
        await origin(...args)
      } catch (e) {
        if (!(e instanceof EmptyHttpError)) {
          throw e
        }
      }

      const resp = await httpPost(path, req, false)
      return resp
    }
  }
}

export function post(path: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    const origin = descriptor.value
    descriptor.value = async (...args: any[]) => {
      const req: any | undefined = args.length > 0 ? args[0] : undefined
      try {
        await origin(...args)
      } catch (e) {
        if (!(e instanceof EmptyHttpError)) {
          throw e
        }
      }

      const resp = await httpPost(path, req, true)
      return resp
    }
  }
}

export function valid(index: number = 0): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    const origin = descriptor.value
    descriptor.value = async (...args: any[]) => {
      if (index < 0) {
        index = 0
      }
      const req = args.length > index ? args[index] : undefined
      if (req.valid !== undefined) {
        await req.valid()
      }
    }
  }
}

export function adapt<T, TResp>(e: (resp: T) => TResp): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor) => {
    const origin = descriptor.value
    descriptor.value = async (...args: any[]) => {
      const tmp = await origin(...args)
      const resp = e(tmp)
      return resp
    }
  }
}
