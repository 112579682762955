/* eslint-disable camelcase */
interface IconFontItem {
  icon_id: string
  name: string
  font_class: string
  unicode: string
  unicode_decimal: number
}

export interface IconFontRoot {
  id: string
  name: string
  font_family: string
  css_prefix_text: string
  description: string
  glyphs: Array<IconFontItem>
}
export function clearIconClass(icon: string) {
  return icon.replace(font_family, '')
}

export function getIconClass(icon: string | undefined | unknown): string {
  if (icon === undefined) {
    return ''
  }
  let ret: string = icon as string
  if (ret.indexOf(font_family) < 0) {
    ret += ' ' + font_family
  }
  return ret
}
let font_family: string = 'iconfont'
const iconMap: { [index: string]: string } = {}
export function getIcon(clz: string): string {
  if (iconMap[clz] === undefined) {
    return iconMap[clz]
  } else {
    return ''
  }
}
export const iconList: Array<string> = []
export function initIcon(iconData: IconFontRoot) {
  if (iconData !== undefined) {
    font_family = iconData.font_family
    if (iconData.glyphs !== undefined) {
      iconData.glyphs.forEach(s => {
        const name = iconData.css_prefix_text + s.font_class
        if (iconMap[name] === undefined) {
          iconMap[name] = s.name
          iconList.push(name)
        }
      })
    }
  }
}
