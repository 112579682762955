/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableEnable, tableText, tableButton } from '@/util/models/admin/tableItem'
import { PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import { fromSlot, fromInput, fromNumber, fromSwitch } from '@/util/models/admin/fromItem'
import { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { Check, Close } from '@element-plus/icons-vue'
import { getIconClass } from '@util/utils/iconUtils'

export interface LicenseItemRequest {
  type: number
  link_id: number
}

export class SaveUserLicenseRequest extends IValid {
  @vr({ required: true, min: 2, message: '授权名称不能为空' })
  license_name: string = ''

  multiple_use: boolean = false
  use_count: number = 0

  @vr({ required: true, type: 'number', min: 0, max: 1000000, message: '库存范围在0-1000000之间' })
  stock: number = 0

  @vr({ required: true, type: 'array', message: '授权信息不能为空' })
  items: Array<LicenseItemRequest> = []

  has_generate: boolean = false
}

export class GenerateUserLicenseRequest extends IValid {
  @vr({ required: true, min: 0, message: '授权ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 0, message: '生成数量不能为空', type: 'number' })
  count: number = 0

  code: string = ''
}

export class EditUserLicenseRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '授权ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '授权名称不能为空' })
  license_name: string = ''

  multiple_use: boolean = false
  use_count: number = 0

  @vr({ required: true, type: 'number', min: 0, max: 1000000, message: '库存范围在0-1000000之间' })
  stock: number = 0

  @vr({ required: true, type: 'array', message: '授权信息不能为空' })
  items: Array<LicenseItemRequest> = []
}
export class QueryUserLicenseRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '授权名称' })
  license_name: string = ''
}
export class GetLicenseCodeRequest extends PageRequest {
  license_id: number = 0
}

export class QueryUserLicenseResponse implements IdEntity {
  @tableButton({
    text: '授权码',
    val: { width: 100, position: 'column' },
    style: { icon: getIconClass('el-icon-menu'), type: 'info', size: 'small' },
    e: { click: 'editCodes' }
  })
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '授权名称', e: { click: 'edit' } })
  license_name: string = ''

  @tableEnable({ header: '重复激活', text: { true: '是', false: '' } })
  multiple_use: boolean = false

  @tableText({ header: '库存', width: 100 })
  stock: number = 0

  @tableText({ header: '已使用', width: 100 })
  use_count: number = 0

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export interface GetUserLicenseResponse extends IdEntity {
  license_name: string
  multiple_use: boolean
  stock: number
  use_count: number
  create_time: string
  modify_time: string
}

export interface GetLicenseCodeResponse {
  code: string
  active: boolean
  use_date: string
}

export class GetLicenseMapResponse implements IdEntity {
  id: number = 0
  @fromInput({ title: '授权名称' })
  license_name: string = ''

  @fromSwitch({ title: '重复激活' }, { icon: Check }, { icon: Close })
  multiple_use: boolean = false

  @fromNumber({ title: '库存', min: 0, max: 1000000, step: 1 })
  stock: number = 0

  use_count: number = 0
  @fromSwitch({ title: '自动生成' }, { icon: Check }, { icon: Close })
  has_generate: boolean = false

  @fromSlot({ title: '授权内容' })
  items: Array<LicenseItemRequest> = []

  apps: Array<number> = []
  tags: Array<number> = []
  roles: Array<number> = []
  create_time: string = ''
  modify_time: string = ''
}

export class RemoveCodeUserLicenseRequest implements IdEntity {
  id: number = 0
  code: string = ''
}

export class LicenseApi {
  @execPostValid('/user/license/save')
  static async save(request: SaveUserLicenseRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/license/edit')
  static async edit(request: EditUserLicenseRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/license/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/license/generate')
  static async generate(request: GenerateUserLicenseRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/license/removeCode')
  static async removeCode(request: RemoveCodeUserLicenseRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserLicenseResponse>('/user/license/query')
  static async query(request: QueryUserLicenseRequest): Promise<PageResponse<QueryUserLicenseResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserLicenseResponse>('/user/license/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserLicenseResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetLicenseMapResponse>('/user/license/getByMap', (resp) => resp ?? { id: 0, items: [] })
  static async getByMap(request: ValidIdRequest): Promise<GetLicenseMapResponse> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserLicenseResponse>('/user/license/codes')
  static async codes(request: GetLicenseCodeRequest): Promise<PageResponse<GetLicenseCodeResponse>> {
    throw new EmptyHttpError()
  }
}

export const LicenseViewObj: ApiWinModel<QueryUserLicenseRequest, QueryUserLicenseResponse> =
  new ApiWinModel(() => new QueryUserLicenseRequest(), () => new QueryUserLicenseResponse())
export const editModel = () => new GetLicenseMapResponse()
export const getModelTitle = (r: GetLicenseMapResponse | GetUserLicenseResponse | QueryUserLicenseResponse) => r.license_name
export const LicenseOper: ISaveEditRequest<GetLicenseMapResponse, SaveUserLicenseRequest, EditUserLicenseRequest> = new ISaveEditRequest(
  {
    entity: () => new GetLicenseMapResponse(),
    save: () => new SaveUserLicenseRequest(),
    edit: () => new EditUserLicenseRequest()
  },
  {
    entity: LicenseApi.getByMap,
    save: LicenseApi.save,
    edit: LicenseApi.edit,
    del: LicenseApi.delete
  }
)
