import CrossStorageClient from './corss'
import { UserToken } from './../models/TokenModels'
import { TokenExpiredError } from '../models/HttpClientModels'
let accUrl: string | undefined
let client: CrossStorageClient | undefined
export default class StorageClientUtil {
  static setUrl(url: string | undefined) {
    accUrl = url
    if (accUrl === undefined || accUrl === '') {
      return undefined
    }
    client = new CrossStorageClient(accUrl)
  }

  static clearToken(): Promise<void> {
    return Promise.resolve()
  }

  static updateToken(token: UserToken): Promise<void> {
    if (accUrl === undefined || accUrl === '' || client === undefined) {
      throw new Error('get hub url fail!')
    }
    return client.onConnect()
      .then(() => {
        const data = {
          data: JSON.stringify(token),
          key: 'userAccessToken',
          startTime: 0
        }
        return client?.set('userAccessToken', JSON.stringify(data))
      }
      ).finally(() => { })
  }

  static getTokenInfo(): Promise<UserToken | undefined> {
    if (accUrl === undefined || accUrl === '' || client === undefined) {
      throw new Error('get hub url fail!')
    }
    return client.onConnect()
      .then(() => client?.get('userAccessToken'))
      .then((tokenStr: string) => {
        if (tokenStr === undefined || tokenStr === null || tokenStr === '') {
          return Promise.reject(new Error('get token fail!'))
        }
        const tokenObj: any = JSON.parse(tokenStr)
        if (tokenObj !== undefined && tokenObj !== null && tokenObj.data !== undefined) {
          const tokenData = JSON.parse(tokenObj.data)
          if (tokenData !== undefined && tokenData.token !== undefined) {
            const token = tokenData.token.token
            if (token === undefined) {
              throw new TokenExpiredError('convert token fail!')
            }
          }
          return tokenData
        }
      }).finally(() => { })
  }

  static async getToken(): Promise<UserToken> {
    const token = await StorageClientUtil.getTokenInfo()
    if (token === undefined) {
      throw new TokenExpiredError('token expired!')
    }
    const expired = token?.token?.expired ?? 0
    const time = new Date().getTime() / 1000
    if (expired === undefined || time > expired) {
      throw new TokenExpiredError('token expired!')
    }
    return token
  }

  static async getRefToken(): Promise<UserToken> {
    const token = await StorageClientUtil.getTokenInfo()
    if (token === undefined) {
      throw new TokenExpiredError('token expired!')
    }
    const expired = token?.refresh?.expired ?? 0
    const time = new Date().getTime() / 1000
    if (expired === undefined || time > expired) {
      throw new TokenExpiredError('refsh token expired!')
    }
    return token
  }
}
