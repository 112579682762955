import Schema from 'async-validator'
import { RuleItem } from 'async-validator/dist-types/interface'
import type { IdEntity } from '@util/models/ServerIdEntity'
import 'reflect-metadata'

type validRule = RuleItem
export type { validRule }
export async function getValid(rule: any, data: any) {
  const validator = new Schema(rule)
  return new Promise<void>((resolve, reject) => {
    validator.validate(data, { first: true }, (errors: any, fields: any) => {
      if (errors) {
        reject(errors[0].message)
      }
      resolve(data)
    })
  })
}
export class ValidError extends Error {
  field: string
  constructor(f: string, msg: string) {
    super(msg)
    this.field = f
  }
}
export class IValid {
  valid(): Promise<void> {
    const ret: any = this
    const rules = Reflect.getMetadata(ValidRuleKey, this)
    if (rules === undefined) {
      return Promise.reject(new Error('not found rules!'))
    }
    const validator = new Schema(rules)
    return new Promise((resolve, reject) => {
      validator.validate(ret, { first: true }, (errors: any, fields: any) => {
        if (errors) {
          reject(new ValidError(errors[0].field, errors[0].message))
        }
        resolve()
      })
    })
  }
}
const ValidRuleKey = 'validRule'
export const vr = (rule: Array<RuleItem> | RuleItem): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(ValidRuleKey, target)) {
      Reflect.defineMetadata(ValidRuleKey, {}, target)
    }
    const rules: { [index: string]: Array<RuleItem> } = Reflect.getMetadata(ValidRuleKey, target)
    if (rule instanceof Array) {
      rules[key.toString()] = rule
    } else {
      rules[key.toString()] = [rule]
    }
    Reflect.defineMetadata(ValidRuleKey, rules, target)
  }
}

export class ValidIdRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'id不能为空', type: 'number' })
  id: number = 0

  constructor(req?: number) {
    super()
    this.id = req ?? 0
  }
}
