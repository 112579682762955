/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow } from '@/util/models/admin/tableItem'
import { PageResponse, PageRequest, IdNameNodeEntity } from '@util/models/ServerBaseEntity'
import { fromInput, fromSlot } from '@/util/models/admin/fromItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveUserRoleRequest extends IValid {
  @vr({ required: true, min: 2, message: '角色名称不能为空' })
  role_name: string = ''
}
export class EditUserRoleRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '角色ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '角色名称不能为空' })
  role_name: string = ''

  actions: Array<number> = []
}
export class QueryUserRoleRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '角色名称' })
  role_name: string = ''
}
export class QueryUserRoleResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '角色名称', e: { click: 'edit' } })
  role_name: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export interface GetUserRoleResponse extends IdEntity {
  role_name: string
  create_time: string
  modify_time: string
}

export class GetUserRoleMapResponse implements IdEntity {
  id: number = 0
  @fromInput({ title: '角色名称' })
  role_name: string = ''

  create_time: string = ''
  modify_time: string = ''

  @fromSlot({ title: '授权路由' })
  actions: Array<number> = []
}

export class RoleApi {
  @execPostValid('/user/role/save')
  static async save(request: SaveUserRoleRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/role/edit')
  static async edit(request: EditUserRoleRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/role/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserRoleResponse>('/user/role/query')
  static async query(request: QueryUserRoleRequest): Promise<PageResponse<QueryUserRoleResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserRoleResponse>('/user/role/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserRoleResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserRoleMapResponse>('/user/role/getByMap', (resp) => resp ?? { id: 0, actions: [] })
  static async getByMap(request: ValidIdRequest): Promise<GetUserRoleMapResponse> {
    throw new EmptyHttpError()
  }
}

export const RoleViewObj: ApiWinModel<QueryUserRoleRequest, QueryUserRoleResponse> =
  new ApiWinModel(() => new QueryUserRoleRequest(), () => new QueryUserRoleResponse())
export const editModel = () => new GetUserRoleMapResponse()
export const getModelTitle = (r: GetUserRoleMapResponse | GetUserRoleResponse | QueryUserRoleResponse) => r.role_name
export const RoleOper: ISaveEditRequest<GetUserRoleMapResponse, SaveUserRoleRequest, EditUserRoleRequest> = new ISaveEditRequest(
  {
    entity: () => new GetUserRoleMapResponse(),
    save: () => new SaveUserRoleRequest(),
    edit: () => new EditUserRoleRequest()
  },
  {
    entity: RoleApi.getByMap,
    save: RoleApi.save,
    edit: RoleApi.edit,
    del: RoleApi.delete
  }
)
