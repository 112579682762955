export interface BaseResponse<T> {
  code: number
  message: string
  data: T
}

export interface PageResponse<T> {
  // eslint-disable-next-line camelcase
  page_count: number
  // eslint-disable-next-line camelcase
  total_count: number
  list: Array<T>
}

export interface PageInfo<T> extends PageResponse<T> {
  // eslint-disable-next-line camelcase
  page_size: number
  // eslint-disable-next-line camelcase
  page_no: number
}

export function checkPageResponse<T>(result: PageResponse<T> | undefined): PageResponse<T> {
  if (result === undefined || result.list === undefined) {
    return {
      list: [],
      page_count: 0,
      total_count: 0
    }
  } else {
    return result
  }
}

export class PageLoadingRef<T> {
  loading: boolean
  response: PageResponse<T>
  query: () => void
  constructor(loading: boolean = false, query: () => void = PageLoadingRef.defQuery, response: PageResponse<T> = { page_count: 0, total_count: 0, list: [] }) {
    this.loading = loading
    this.response = response
    this.query = query
  }

  static defQuery(): void {
    // def query
  }
}

export class PageRequest {
  // eslint-disable-next-line camelcase
  page_size: number = 20
  // eslint-disable-next-line camelcase
  page_no: number = 1
}

export interface BaseRequest<T> {
  parameter: T | undefined
}
export const HttpLoginCode = 6

export class HttpError extends Error {
  code = 0
  constructor(code: number, msg: string) {
    super('code:' + code + ', msg:' + msg)
    this.code = code
  }
}
export class LogingError extends Error {
}
export class RefreshError extends Error {
}

export class IAdapt<T> {
  entity: T
  set(content: string) {
    this.entity = JSON.parse(content) as T
  }

  get(num: number | undefined) {
    return this.entity === undefined ? '' : num === undefined ? JSON.stringify(this.entity) : JSON.stringify(this.entity, null, num as any)
  }

  constructor(entity: T) {
    this.entity = entity
  }
}

export interface IdContentEntity {
  id: number,
  content: string
}
export interface ExecuteEntity {
  success: boolean
}
export interface ExecuteResponse {
  success: boolean
}
export interface UploadFileResponse extends ExecuteResponse {
  url: string
  filename: string
  md5: string
}
export interface IdNameEntity {
  id: number
  name: string
}

export interface IdNameRemarkEntity {
  id: number
  name: string
  remark: string
}
export interface IdNameRemarkExEntity {
  id: number
  name: string
  remark: string
  ohter: string
}

export interface KVEntity<TK, TV> {
  key: TK
  value: TV
}
export interface IdNameNodeEntity extends IdNameEntity {
  nodes: Array<IdNameNodeEntity>
}
