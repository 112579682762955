/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableEnable, tableIdRow, tableText, tableIcon, tableButton } from '@/util/models/admin/tableItem'
import { IdNameEntity, PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { Check, Close } from '@element-plus/icons-vue'
import { getIconClass } from '@/util/utils/iconUtils'

export class SaveUserAppRequest extends IValid {
  @vr({ required: true, min: 2, message: '应用名称不能为空' })
  app_name: string = ''

  @vr({ required: true, min: 2, message: '应用URL不能为空' })
  app_url: string = ''

  @vr({ required: true, min: 2, message: '应用图标不能为空' })
  app_icon: string = ''

  @vr({ max: 1024, message: '应用说明不能超过1000个字符' })
  app_desc: string = ''

  can_select_group: boolean = true
  display_login: boolean = false
}

export interface UserAppMenuRequest extends IdEntity {
  id: number
  parent: string
  menu_name: string
  menu_icon: string
  menu_title: string
  menu_desc: string
}
export class UserAppMenuEdit {
  @fromSlot({ title: '菜单名称' })
  menu_name: string = ''

  @fromSlot({ title: '菜单图标' })
  menu_icon: string = ''

  @fromInput({ title: '菜单文字' })
  menu_title: string = ''

  @areaInput({ title: '菜单说明', min: 4, maxlength: 1000 })
  menu_desc: string = ''

  o: UserAppMenuRequest | undefined
}
export class UserAppMenu implements UserAppMenuRequest {
  id: number
  parent: string
  menu_name: string
  menu_icon: string
  menu_title: string
  menu_desc: string
  move: boolean = false
  items: Array<UserAppMenu> = []
  constructor(o: UserAppMenuRequest | UserAppMenu) {
    this.id = o.id
    this.parent = o.parent
    this.menu_name = o.menu_name
    this.menu_icon = o.menu_icon
    this.menu_title = o.menu_title
    this.menu_desc = o.menu_desc
  }

  adapt(ret: Array<UserAppMenuRequest>) {
    const tmp: UserAppMenuRequest = {
      id: this.id,
      parent: this.parent,
      menu_name: this.menu_name,
      menu_icon: this.menu_icon,
      menu_title: this.menu_title,
      menu_desc: this.menu_desc
    }
    ret.push(tmp)
    if ((this.items ?? []).length > 0) {
      this.items.forEach(s => s.adapt(ret))
    }
  }
}

export class EditUserAppRequest implements IdEntity {
  @vr({ required: true, min: 0, message: '应用ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '应用URL不能为空' })
  app_name: string = ''

  @vr({ required: true, min: 2, message: '应用地址不能为空' })
  app_url: string = ''

  @vr({ required: true, min: 2, message: '应用图标不能为空' })
  app_icon: string = ''

  @vr({ max: 1024, message: '应用说明不能超过1000个字符' })
  app_desc: string = ''

  display_login: boolean = true
  can_select_group: boolean = false
}
export class EditMenuRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '应用ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '应用URL不能为空' })
  app_name: string = ''

  menus: Array<UserAppMenuRequest> = []
}

export class QueryUserAppRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '应用名称' })
  app_name: string = ''
}

export class QueryUserAppResponse implements IdEntity {
  @tableButton({
    text: '菜单设置',
    val: { width: 100, position: 'column' },
    style: { icon: getIconClass('el-icon-menu'), type: 'info', size: 'small' },
    e: { click: 'editMenu' }
  })
  @tableIdRow()
  id: number = 0

  @tableIcon({ header: '图标', width: 50 })
  app_icon: string = ''

  @tableEditUrl({ header: '应用名称', e: { click: 'edit' } })
  app_name: string = ''

  @tableText({ header: '应用URL' })
  app_url: string = ''

  @tableText({ header: '应用说明' })
  app_desc: string = ''

  @tableEnable({ header: '选择分组', text: { true: '可选', false: '禁止' } })
  can_select_group: boolean = false

  @tableEnable({ header: '是否显示', text: { true: '显示', false: '隐藏' } })
  display_login: boolean = false

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export interface GetByIdUserAppResponse extends IdEntity {
  app_name: string
  app_url: string
  app_icon: string
  app_desc: string
  can_select_group: boolean
  display_login: boolean
  create_time: string
  modify_time: string

}

export interface EditUserAppMenusRequest extends IdEntity {
  menus: Array<UserAppMenuRequest>
}

export class GetByMapUserAppResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '应用名称' })
  app_name: string = ''

  @fromInput({ title: '应用URL' })
  app_url: string = ''

  @fromSwitch({ title: '选择分组' }, { icon: Check }, { icon: Close })
  can_select_group: boolean = false

  @fromSwitch({ title: '是否显示' }, { icon: Check }, { icon: Close })
  display_login: boolean = false

  @fromSlot({ title: '应用图标' })
  app_icon: string = ''

  @areaInput({ title: '应用说明', min: 4, maxlength: 1000 })
  app_desc: string = ''

  group_id: number = 0

  create_time: string = ''
  modify_time: string = ''
  menus: Array<UserAppMenuRequest> = []
}

export class AppApi {
  @execPostValid('/user/app/save')
  static async save(request: SaveUserAppRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/app/edit')
  static async edit(request: EditUserAppRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/app/delete')
  static async delete(request: IdEntity): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserAppResponse>('/user/app/query')
  static async query(request: QueryUserAppRequest): Promise<PageResponse<QueryUserAppResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetByIdUserAppResponse>('/user/app/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetByIdUserAppResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetByMapUserAppResponse>('/user/app/getByMap', (resp) => resp ?? { id: 0, menus: [] })
  static async getByMap(request: ValidIdRequest): Promise<GetByMapUserAppResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/app/saveMenu')
  static async saveMenus(request: EditUserAppMenusRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }
}

export const AppViewObj: ApiWinModel<QueryUserAppRequest, QueryUserAppResponse> =
  new ApiWinModel(() => new QueryUserAppRequest(), () => new QueryUserAppResponse())
export const editModel = () => new GetByMapUserAppResponse()
export const getModelTitle = (r: GetByMapUserAppResponse | GetByIdUserAppResponse | QueryUserAppResponse) => r.app_name
export const AppOper: ISaveEditRequest<GetByIdUserAppResponse, SaveUserAppRequest, EditUserAppRequest> = new ISaveEditRequest(
  {
    entity: () => new GetByMapUserAppResponse(),
    save: () => new SaveUserAppRequest(),
    edit: () => new EditUserAppRequest()
  },
  {
    entity: AppApi.getById,
    save: AppApi.save,
    edit: AppApi.edit,
    del: AppApi.delete
  }
)
