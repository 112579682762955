/* eslint-disable camelcase */
import { queryDropdown, queryText, queryContent } from '@/util/models/admin/queryItem'
import { PageResponse, PageRequest, IdNameEntity } from '@util/models/ServerBaseEntity'
import { fromInput, fromSwitch, fromNumber } from '@/util/models/admin/fromItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPost, adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { tableEditUrl, tableText, tableDate, tableEnable, tableIdNotAddRow } from '@util/models/admin/tableItem'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { Check, Close } from '@element-plus/icons-vue'

export class QueryUserInfoRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '用户名' })
  user_name: string = ''

  @queryDropdown({ 启用: 1, 禁用: 0, 所有: 'NULL' }, { text: '是否启用' })
  enable?: number | 'NULL' = 'NULL'
}

export enum UserInfoLoginType {
  password,
  token
}
export interface UserLoginAppResponse {
  id: number
  name: string
}
export interface UserLoginSecretResponse {
  id: number
  token: string
  secret: string
  address: string
  port: number
  desc: string,
  expired: Date | string
  group_id: number
  group_name: string
}
export interface UserLoginInfoResponse {
  id: number
  name: string
  type: UserInfoLoginType
  desc: string
  row: UserLoginAppResponse | UserLoginSecretResponse
}

export interface UserLoginResponse {
  apps: Array<UserLoginAppResponse>
  secrets: Array<UserLoginSecretResponse>
}
export class UserGroupRequest {
  id: number = 0
  group_name: string = ''
  group_desc: string = ''
}

export class SaveUserInfoRequest extends IValid {
  @vr({ required: true, min: 2, message: '用户名称不能为空' })
  user_name: string = ''

  enable: boolean = true

  @vr({ required: true, min: 0, max: 10, message: '用户权限范围在0-10之间' })
  permission: number = 0

  apps: Array<number> = []

  roles: Array<number> = []

  groups: Array<UserGroupRequest> = []
}
export class EditUserInfoRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '用户ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '用户名称不能为空' })
  user_name: string = ''

  enable: boolean = true

  @vr({ required: true, min: 0, max: 10, message: '用户权限范围在0-10之间', type: 'number' })
  permission: number = 0

  apps: Array<number> = []

  roles: Array<number> = []

  groups: Array<UserGroupRequest> = []
}

export interface SavePassUserInfoRequest extends IdEntity {
  login_name: string
  password: string

}

export interface SaveSecretUserInfoRequest extends IdEntity {
  secret: string
  address: string
  port: number
  desc: string,
  expired: Date | string
  group_id: number
}

export interface InsertPassUserInfoRequest extends IdEntity {
  login_name: string
  password: string

}

export interface EditPassUserInfoRequest extends IdEntity {
  login_name: string
  password: string

}
export interface EditSecretUserInfoRequest extends IdEntity {
  token: string
  expired: Date | string
  group_id: number | undefined
}
export interface InsertSecretUserInfoRequest extends IdEntity {
  secret: string
  address: string
  port: number
  desc: string,
  expired: Date | string,
  group_id: number | undefined
}

export class QueryUserInfoResponse {
  @tableIdNotAddRow()
  id: number = 0

  @tableEditUrl({ header: '用户名', e: { click: 'edit' } })
  user_name: string = ''

  @tableEnable({ header: '是否有效', text: { true: '启用', false: '无效' } })
  enable: boolean = false

  @tableText({ header: '权限', width: 100 })
  permission: number = 0

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export interface GetUserInfoResponse extends IdEntity {
  user_name: string
  enable: boolean
  permission: number
  create_time: string
  modify_time: string
}
export interface RemoveUserLoginRequest extends IdEntity {
  type: UserInfoLoginType
}
export class GetUserMapResponse implements IdEntity {
  id: number = 0
  @fromInput({ title: '用户名' })
  user_name: string = ''

  @fromSwitch({ title: '是否有效' }, { icon: Check }, { icon: Close })
  enable: boolean = true

  @fromNumber({ title: '用户权限', min: 0, max: 10, step: 1 })
  permission: number = 0

  create_time: string = ''
  modify_time: string = ''
  apps: Array<IdNameEntity> = []
  roles: Array<IdNameEntity> = []
  groups: Array<UserGroupRequest> = []
}

export class QueryUserInfoRow implements QueryUserInfoResponse {
  id: number = 0
  user_name: string = ''
  enable: boolean = false
  permission: number = 0
  create_time: string = ''
  modify_time: string = ''
  items: Array<UserLoginInfoResponse> = []
  loading: boolean = false
  static convert(r: QueryUserInfoResponse): QueryUserInfoRow {
    const tmp = new QueryUserInfoRow()
    tmp.id = r.id
    tmp.user_name = r.user_name
    tmp.enable = r.enable
    tmp.permission = r.permission
    tmp.create_time = r.create_time
    tmp.modify_time = r.modify_time
    tmp.items = []
    tmp.loading = false
    return tmp
  }
}

export class UserApi {
  @execPostValid('/user/save')
  static async save(request: SaveUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/edit')
  static async edit(request: EditUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserInfoResponse>('/user/query')
  static async query(request: QueryUserInfoRequest): Promise<PageResponse<QueryUserInfoResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserInfoResponse>('/user/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserInfoResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserMapResponse>('/user/getByMap', (resp) => resp ?? { id: 0, apps: [], roles: [] })
  static async getByMap(request: ValidIdRequest): Promise<GetUserMapResponse> {
    throw new EmptyHttpError()
  }

  @queryPost<GetUserMapResponse>('/user/queryAllGroup')
  static async queryAllGroup(request: ValidIdRequest): Promise<PageResponse<IdNameEntity>> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/save/pass')
  static async savePassUser(request: SavePassUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/save/secret')
  static async saveSecretUser(request: SaveSecretUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @adaptPost<UserLoginResponse>('/user/login/query', (resp) => resp ?? { apps: [], secrets: [] })
  static async queryUserLoginList(request: IdEntity): Promise<UserLoginResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/insert/pass')
  static async insertUserLoginByPass(request: InsertPassUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/edit/pass')
  static async editUserLoginByPass(request: EditPassUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/insert/secret')
  static async insertUserLoginBySecret(request: InsertSecretUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/edit/secret')
  static async editUserLoginBySecret(request: EditSecretUserInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/login/remove')
  static async removeUserLogin(request: RemoveUserLoginRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }
}

export const UserViewObj: ApiWinModel<QueryUserInfoRequest, QueryUserInfoResponse> =
  new ApiWinModel(() => new QueryUserInfoRequest(), () => new QueryUserInfoResponse())
export const editModel = () => new GetUserMapResponse()
export const getModelTitle = (r: GetUserMapResponse | QueryUserInfoResponse | GetUserInfoResponse) => r.user_name
export const UserOper: ISaveEditRequest<GetUserMapResponse, SaveUserInfoRequest, EditUserInfoRequest> = new ISaveEditRequest(
  {
    entity: () => new GetUserMapResponse(),
    save: () => new SaveUserInfoRequest(),
    edit: () => new EditUserInfoRequest()
  },
  {
    entity: UserApi.getByMap,
    save: UserApi.save,
    edit: UserApi.edit,
    del: UserApi.delete
  }
)
