import { Router, RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import { Store } from 'vuex'
import { getIconClass } from './iconUtils'
import { ILastPathInfo, IMenuCache, IAppInfo } from './../storage/routerCache'

export class RouterUtils {
  routerCache: { [index: string]: RouteRecordRaw } = {}
  mountCache: { [index: string]: Array<RouteRecordRaw> } = {}
  hasRoot: Array<string> = []
  index: number = 0
  use(row: RouteRecordRaw | Array<RouteRecordRaw>): RouterUtils {
    if (row instanceof Array) {
      this.addList(row as Array<RouteRecordRaw>)
    } else {
      this.add(row as RouteRecordRaw)
    }
    return this
  }

  addMount(row: RouteRecordRaw): boolean {
    if (row !== undefined && row.meta !== undefined) {
      const name: string | undefined = row.name?.toString()
      let ret = false
      if (name === undefined) {
        return ret
      }
      if (row.meta.mount !== undefined) {
        ret = true
        const mount = row.meta.mount as string
        row.meta.show = false
        if (this.mountCache[mount] === undefined) {
          this.mountCache[mount] = [row]
        } else {
          this.mountCache[mount].push(row)
        }
      }
      if (row.children !== undefined) {
        for (let j = row.children.length; j >= 0; j--) {
          if (this.addMount(row.children[j])) {
            row.children.splice(j, 1)
          }
        }
      }
      return ret
    }
    return false
  }

  add(row: RouteRecordRaw): void {
    const name: string | undefined = row.name?.toString()
    if (name === undefined) {
      console.warn('pushed menu fail,with meun name is empty')
    } else if (this.routerCache[name] !== undefined) {
      console.warn('pushed menu fail,with ' + name + ' is repeat')
    } else {
      if (row.meta === undefined) {
        row.meta = {}
      }
      row.meta.index = this.index++
      if (this.isRoot(row)) {
        this.hasRoot.push(name)
      }
      this.routerCache[name] = (row)
      this.addMount(row)
    }
  }

  addList(rows: Array<RouteRecordRaw>): void {
    rows.forEach(s => this.add(s))
  }

  get(): Array<RouteRecordRaw> {
    return this.hasRoot.map(s => this.routerCache[s] ?? undefined).filter(s => s !== undefined)
  }

  getAllCache(): Array<RouteRecordRaw> {
    const tmp: Array<RouteRecordRaw> = []
    for (const key in this.routerCache) {
      tmp.push(this.routerCache[key])
    }
    return tmp
  }

  private addMatched(parent: RouteRecordRaw | undefined, tmp: RouteRecordRaw) {
    if (tmp.meta !== undefined) {
      if (parent !== undefined) {
        const arr: any = parent.meta?.matched ?? []
        tmp.meta.matched = arr.concat([{
          title: parent?.meta?.title ?? '',
          path: parent?.path,
          icon: parent?.meta?.icon ?? ''
        }])
      } else {
        tmp.meta.matched = []
      }
    }
  }

  private addMountRouter(parent: RouteRecordRaw, row: RouteRecordRaw, result: Array<RouteRecordRaw>, root: Array<RouteRecordRaw>) {
    if (row !== undefined) {
      const tmp = {
        path: row.path,
        component: row.component,
        name: row.name,
        meta: JSON.parse(JSON.stringify(row.meta)),
        children: [],
        redirect: row.redirect
      }
      this.addMatched(parent, tmp as RouteRecordRaw)
      if (row.meta !== undefined && row.meta.renderParent !== undefined && row.meta.renderParent === false) {
        root.push(tmp as RouteRecordRaw)
      } else {
        result.push(tmp as RouteRecordRaw)
      }
    }
  }

  private eachSetRouter(parent: RouteRecordRaw | undefined, row: RouteRecordRaw, result: Array<RouteRecordRaw>, root: Array<RouteRecordRaw>, hash: { [index: string]: IMenuCache }): boolean {
    if (hash !== undefined && row !== undefined) {
      const children: Array<RouteRecordRaw> = []
      let flag = false
      if (row.children !== undefined && row.children.length > 0) {
        row.children.forEach((s: RouteRecordRaw) => {
          if (this.eachSetRouter(row, s, children, root, hash)) {
            flag = true
          }
        })
      }
      if (row.name !== undefined) {
        const serverInfo = hash[row.name.toString()]
        if (flag || serverInfo !== undefined) {
          const tmp = {
            path: row.path,
            component: row.component,
            name: row.name,
            meta: JSON.parse(JSON.stringify(row.meta)),
            children,
            redirect: row.redirect
          }

          tmp.meta.title = serverInfo?.title ?? tmp.meta.title ?? row.name
          tmp.meta.desc = serverInfo?.desc ?? tmp.meta.desc ?? ''
          tmp.meta.icon = serverInfo?.icon ?? tmp.meta.icon ?? ''
          tmp.meta.icon = getIconClass(tmp.meta?.icon)
          result.push(tmp as RouteRecordRaw)
          this.addMatched(parent, tmp as RouteRecordRaw)
          const mount = this.mountCache[row.name.toString()]
          if (mount !== undefined) {
            mount.forEach(s => this.addMountRouter(tmp as RouteRecordRaw, s, children, root))
          }

          return true
        }
      }
    }
    return false
  }

  isRoot(row: RouteRecordRaw): boolean {
    return (row?.meta?.hasRoot ?? false) as boolean
  }

  setRouter(map: Array<IMenuCache> | undefined): Array<RouteRecordRaw> {
    const result: Array<RouteRecordRaw> = []
    if (map === undefined || map.length === 0) {
      map = [{
        name: '首页',
        icon: undefined,
        desc: undefined,
        title: undefined
      }]
    }
    if (map !== undefined) {
      for (const url of this.hasRoot) {
        if (map.findIndex(s => s.name === url) < 0) {
          const tmp = this.routerCache[url]
          if (tmp !== undefined) {
            map.push({
              name: tmp.name?.toString() ?? '',
              icon: (tmp.meta?.icon ?? '') as string,
              title: (tmp.meta?.title ?? '') as string,
              desc: (tmp.meta?.desc ?? '') as string
            })
          }
        }
      }

      const viewMap: { [index: string]: IMenuCache } = {}
      map.forEach(s => { viewMap[s.name] = s })
      for (const key in this.routerCache) {
        const value = this.routerCache[key]
        if (value !== undefined) {
          this.eachSetRouter(undefined, value, result, result, viewMap)
        }
      }
    }
    return result
  }
}
let useStore: Store<any> | undefined
export const routerUtils = new RouterUtils()
function changeHttps() {
  const https = window.location.protocol.indexOf('https:') >= 0
  if (useStore !== undefined) {
    useStore.commit('routerModule/updateHttps', https)
  }
}
function changeTitle(to: RouteLocationNormalized) {
  if (to.meta.title) {
    document.title = projectName + '-' + to.meta.title as string
  } else {
    document.title = projectName ?? document.title
  }
}
function changeLastPath(to: RouteLocationNormalized) {
  if (useStore !== undefined) {
    useStore.commit('routerModule/setLastUrl', { name: to.name?.toString() ?? '', fullPath: to.fullPath })
  }
}
function getMenuList(): Array<IMenuCache> | undefined {
  const app: IAppInfo = useStore?.getters['routerModule/getAppInfo']
  return app?.menus?.map(s => {
    return {
      name: s.name,
      icon: s.icon,
      title: s.title,
      desc: s.desc
    }
  })
}
export function getPathName(path: string | undefined) {
  if (path === undefined) {
    return undefined
  }
  const index = path.indexOf('?')
  return index < 0 ? path : path.substring(0, index)
}
function getlastFullPath() {
  const url: ILastPathInfo = useStore?.getters['routerModule/getLastUrl']
  return { host: getPathName(url?.fullPath), url: url?.fullPath }
}

export function changeRoute(router: Router, menus: Array<IMenuCache> | undefined) {
  const result = routerUtils.setRouter(menus === undefined ? undefined : menus)
  router.getRoutes().forEach(s => {
    const name: string | undefined = s.name?.toString()
    if (name !== undefined) {
      router.removeRoute(name)
    }
  })
  let toPath = '/'
  const lastName = getlastFullPath()
  result.forEach(s => {
    router.addRoute(s)
  })
  router.options.routes = result
  if (lastName !== undefined && lastName.host !== undefined) {
    const index = router.getRoutes().findIndex(s => s.path === lastName.host)
    if (index >= 0) {
      toPath = lastName.url ?? '/'
    }
  }
  if (toPath === '/') {
    const urls = router.getRoutes().filter(s => (s.meta?.defaultPath ?? false) === true)
    if (urls.length > 0 && urls[0] !== undefined) {
      toPath = urls[0].path
    }
  }
  router.replace(toPath)
  // 强制刷新菜单
  useStore?.commit('routerModule/updateToken')
}
let projectName: string | undefined
let isInit = false
export function hookRoute(router: Router, store: Store<any>, project: string) {
  projectName = project
  useStore = store
  router.beforeEach((to, from, next) => {
    changeTitle(to)
    changeLastPath(to)
    if (!isInit) {
      changeHttps()
      useStore?.commit('routerModule/setLastUrl', { name: '', fullPath: process.env.VUE_APP_INDEX })
      const defaultM = getMenuList()
      changeRoute(router, defaultM)
      next({
        path: to.path,
        params: to.params,
        query: to.query
      })
      isInit = true
      return
    }
    next()
  })
}
