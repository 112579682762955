import { getIconClass } from '@/util/utils/iconUtils'
import 'reflect-metadata'
export enum ITableType {
  ButtonTableItem,
  AttrTableItem,
  TextTableItem,
  UrlTableItem,
  PaginationTableItem,
  DateTableItem,
  EnableTableItem,
  IconTableItem,
  Slot
}
export interface ITableClickItem {
  click?: string
  args?: Array<any>
}
export interface ITableItem {
  field: string
  type: ITableType
  overflow: boolean
  headerText: string
  width: number | undefined
}

export interface TableButtonInfo {
  width?: number | undefined
  position?: 'header' | 'column' | undefined
}
export interface TableButtonStyle {
  icon?: string | any
  type?: 'primary' | 'success' | 'info' | 'warning' | 'danger'
  size?: 'large' | 'default' | 'small'
}
declare type TableRule = Array<ITableItem>
export interface ButtonPopItem {
  title: string
  confirm?: string
  cancel?: string
}
export interface TableButtonItem extends ITableClickItem, TableButtonStyle, TableButtonInfo, ButtonPopItem {
  text: string
  check?: (row: any) => boolean
}
export class ButtonTableItem implements ITableItem {
  field: string
  headerText: string
  type: ITableType
  overflow: boolean = true
  value: TableButtonItem
  width: number | undefined
  constructor(val: TableButtonItem) {
    this.field = val.text
    this.headerText = val.text
    this.value = val
    this.type = ITableType.ButtonTableItem
  }
}

export declare type PaginationLayout = 'sizes' | 'prev' | 'pager' | 'next' | 'jumper' | '->' | 'total' | 'slot'
export class PaginationTableItem implements ITableItem {
  field: string
  headerText: string
  type: ITableType
  overflow: boolean = true
  pageSizes: Array<number>
  layout: string
  width: number | undefined
  constructor(fieldName: string, pageSizesStr: Array<number>, layoutStr: string) {
    this.field = fieldName
    this.headerText = ''
    this.pageSizes = pageSizesStr
    this.layout = layoutStr
    this.type = ITableType.PaginationTableItem
  }
}
export class AttrTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  value: any
  width: number | undefined
  constructor(fieldName: string, val: any) {
    this.field = fieldName
    this.headerText = ''
    this.value = val
    this.type = ITableType.AttrTableItem
  }
}
export class TextTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.TextTableItem
  }
}
export class SlotTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.Slot
  }
}
export class IconTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  color: string | undefined
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.IconTableItem
  }
}
export interface EnableTableItemInfo {
  true?: string
  false?: string
}
export class EnableTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  iconShow?: boolean
  icon?: EnableTableItemInfo
  color?: EnableTableItemInfo
  text?: EnableTableItemInfo
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.EnableTableItem
  }
}
export class DateTableItem implements ITableItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  isShowSec?: boolean = false
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.DateTableItem
  }
}
export class UrlTableItem implements ITableItem, ITableClickItem {
  field: string
  overflow: boolean = true
  headerText: string
  type: ITableType
  width: number | undefined
  click?: string
  args?: Array<any>
  constructor(fieldName: string, header: string) {
    this.field = fieldName
    this.headerText = header
    this.type = ITableType.UrlTableItem
  }
}
const tableKey = 'tableColumnRule'
export const tableContent = (attr?: { [index: string]: any } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (attr !== undefined) {
      if (!Reflect.hasMetadata(tableKey, target)) {
        Reflect.defineMetadata(tableKey, [], target)
      }
      let rules: TableRule = Reflect.getMetadata(tableKey, target)
      if (rules === undefined) {
        rules = []
      }
      for (const key in attr) {
        rules.push(new AttrTableItem(key, attr[key]))
      }
      Reflect.defineMetadata(tableKey, rules, target)
    }
  }
}
export const tablePagination = (
  attr?: {
    pageSizes?: Array<number> | undefined,
    layout?: string | Array<PaginationLayout> | undefined
  } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (attr === undefined) {
      attr = {
        pageSizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper'
      }
    }
    if (attr !== undefined) {
      if (!Reflect.hasMetadata(tableKey, target)) {
        Reflect.defineMetadata(tableKey, [], target)
      }
      let rules: TableRule = Reflect.getMetadata(tableKey, target)
      if (rules === undefined) {
        rules = []
      }
      let pageSizes: Array<number> = []
      if (attr?.pageSizes !== undefined) {
        pageSizes = attr.pageSizes
      }
      let layout = ''
      if (attr?.layout !== undefined) {
        if (attr.layout instanceof Array) {
          layout = attr.layout.join(',')
        } else {
          layout = attr.layout as string
        }
      }

      rules.push(new PaginationTableItem(key.toString(), pageSizes, layout))
      Reflect.defineMetadata(tableKey, rules, target)
    }
  }
}

export const tableRowKey = (): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    let rules: TableRule = Reflect.getMetadata(tableKey, target)
    if (rules === undefined) {
      rules = []
    }
    rules.push(new AttrTableItem('row-key', key))
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export const tableIdRow = (): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    tableRowKey()(target, key)
    tablePagination()(target, key)
    tableButton({
      text: '新增',
      val: { position: 'header' },
      style: { icon: getIconClass('el-icon-xinzeng1'), type: 'primary', size: 'default' },
      e: { click: 'insert' }
    })(target, key)
    tableButton(
      {
        text: '删除',
        val: { width: 90, position: 'column' },
        style: { icon: getIconClass('el-icon-shanchu'), type: 'danger', size: 'small' },
        e: { click: 'remove' },
        pop: {
          title: '是否删除当前项目?'
        }
      })(target, key)
    tableButton(
      {
        text: '修改',
        val: { width: 90, position: 'column' },
        style: { icon: getIconClass('el-icon-bianji'), size: 'small' },
        e: { click: 'edit' }
      })(target, key)
    tableText({ header: '#', width: 80 })(target, key)
  }
}

export const tableIdNotAddRow = (): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    tableRowKey()(target, key)
    tablePagination()(target, key)
    tableButton(
      {
        text: '删除',
        val: { width: 90, position: 'column' },
        style: { icon: getIconClass('el-icon-shanchu'), type: 'danger', size: 'small' },
        e: { click: 'remove' },
        pop: {
          title: '是否删除当前项目?'
        }
      })(target, key)
    tableButton(
      {
        text: '修改',
        val: { width: 90, position: 'column' },
        style: { icon: getIconClass('el-icon-bianji'), size: 'small' },
        e: { click: 'edit' }
      })(target, key)
    tableText({ header: '#', width: 80 })(target, key)
  }
}
export const tableButton = (info: {
  text: string,
  val?: TableButtonInfo | undefined,
  style?: TableButtonStyle | undefined,
  e?: ITableClickItem | undefined, pop?: ButtonPopItem, check?: (row: any) => boolean
}): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const btmp: TableButtonItem = {
      check: info.check,
      text: info.text,
      width: info.val?.width,
      position: info.val?.position,
      icon: info.style?.icon,
      size: info.style?.size,
      type: info.style?.type,
      click: info.e?.click,
      args: info.e?.args,
      title: info.pop?.title ?? '',
      confirm: info.pop?.confirm ?? '确认',
      cancel: info.pop?.cancel ?? '取消'
    }
    rules.push(new ButtonTableItem(btmp))
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export const tableText = (info?: { header?: string | undefined, width?: number | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new TextTableItem(key.toString(), info?.header ?? key.toString())
    tmp.width = info?.width
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export const tableSlot = (info?: { header?: string | undefined, width?: number | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new SlotTableItem(key.toString(), info?.header ?? key.toString())
    tmp.width = info?.width
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export const tableIcon = (info?: { header?: string | undefined, width?: number | undefined, color?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new IconTableItem(key.toString(), info?.header ?? key.toString())
    tmp.width = info?.width
    tmp.color = info?.color
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export interface TableEnableArgs {
  header?: string
  width?: number
  iconShow?: boolean
  icon?: EnableTableItemInfo
  color?: EnableTableItemInfo
  text?: EnableTableItemInfo
}
export const tableEnable = (info?: TableEnableArgs | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new EnableTableItem(key.toString(), info?.header ?? key.toString())
    tmp.width = info?.width ?? 120
    tmp.iconShow = info?.iconShow ?? true
    tmp.icon = info?.icon
    tmp.color = info?.color
    tmp.text = info?.text ?? {
      true: '是',
      false: '否'
    }
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}
export const tableDate = (info?: { header?: string | undefined, width?: number | undefined, isShowSec?: boolean | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new DateTableItem(key.toString(), info?.header ?? key.toString())
    tmp.width = info?.width ?? 100
    tmp.isShowSec = info?.isShowSec
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}

export const tableEditUrl = (info?: { header?: string | undefined, width?: number | undefined, e?: ITableClickItem | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(tableKey, target)) {
      Reflect.defineMetadata(tableKey, [], target)
    }
    const rules: TableRule = Reflect.getMetadata(tableKey, target)
    const tmp = new UrlTableItem(key.toString(), info?.header ?? key.toString())
    tmp.click = info?.e?.click
    tmp.args = info?.e?.args
    tmp.width = info?.width
    rules.push(tmp)
    Reflect.defineMetadata(tableKey, rules, target)
  }
}

// export class TableResponse {
//   @tableRowKey()
//   @tablePagination({ pageSizes: [10, 20, 50, 100], layout: 'total, sizes, prev, pager, next, jumper' })
//   @tableContent({ width: '100%', 'row-key': 'id' })
//   @tableButton({ position: 'header', text: 'button', click: 'clickButton', args: [] })
//   @tableEditUrl({ header: '编号' })
//   id: number = 1

//   @tableEditUrl({ ref: 'id' })
//   name: string = 'test'

//   @tableText()
//   desc: string = 'desc'
// }

export interface TableButtonRenderInfo {
  header: Array<TableButtonItem>
  content: Array<TableButtonItem>
}

export interface TableColumnRenderInfo {
  header: string
  content: ITableItem
}

export interface TableRenderInfo {
  tableAttr: { [index: string]: any }
  column: Array<TableColumnRenderInfo>
  button: TableButtonRenderInfo
  pagination: {
    pageSizes: Array<number> | undefined
    layout: string | undefined
  } | undefined

}

export function RenderTableContent(o: any | Array<any>): TableRenderInfo | undefined {
  if (o instanceof Array) {
    if (o != null && o.length > 0) {
      o = o[0]
    } else {
      return undefined
    }
  }
  if (!Reflect.hasMetadata(tableKey, o)) {
    return undefined
  }
  const rules: TableRule = Reflect.getMetadata(tableKey, o)
  if (rules === undefined) {
    return undefined
  }
  const ret: TableRenderInfo = {
    tableAttr: {},
    column: [],
    button: {
      header: [],
      content: []
    },
    pagination: {
      pageSizes: undefined,
      layout: undefined
    }
  }
  const attr: { [index: string]: any } = {}
  const button: {
    header: Array<TableButtonItem>,
    content: Array<TableButtonItem>,
  } = { header: [], content: [] }
  const columns: Array<ITableItem> = []
  for (const rule of rules) {
    if (rule instanceof PaginationTableItem) {
      ret.pagination = {
        pageSizes: rule.pageSizes,
        layout: rule.layout
      }
    } else if (rule instanceof AttrTableItem) {
      attr[rule.field] = rule.value
    } else if (rule instanceof ButtonTableItem) {
      if (rule.value.position === 'header') {
        button.header.push(rule.value)
      } else {
        button.content.push(rule.value)
      }
    } else {
      columns.push(rule)
    }
  }
  ret.tableAttr = attr
  for (const iterator of columns) {
    ret.column.push({
      header: iterator.headerText,
      content: iterator
    })
  }
  button.header.reverse().forEach(s => ret.button.header.push(s))
  button.content.reverse().forEach(s => ret.button.content.push(s))
  return ret
}

// const tmp = [new TableResponse()]
// const ret = RenderTableContent(tmp)
// console.log(ret)
