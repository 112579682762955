import { createApp } from 'vue'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import { hookRoute } from '@util/utils/RouterUtils'
import { store, key } from '@/store'
import { setHttpBaseUrl } from '@util/storage/routerCache'
import '@/assets/icon/iconfont.css'
import { initIcon, IconFontRoot } from '@util/utils/iconUtils'
import { initHttpClient } from '@util/utils/HttpClient'
import 'element-plus/theme-chalk/src/message-box.scss'
import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/loading.scss'
import 'element-plus/theme-chalk/src/notification.scss'
import StorageClientUtil from '@util/storage/storageClientUtil'

const iconData: IconFontRoot = require('@/assets/icon/iconfont.json')
setHttpBaseUrl({
  http: process.env.VUE_APP_API_ROOT ?? '',
  https: process.env.VUE_APP_API_ROOT_S ?? '',
  login: process.env.VUE_APP_Login ?? ''
})
StorageClientUtil.setUrl(process.env.VUE_APP_CORSS_URL)
const app = createApp(App)
initIcon(iconData)
app.use(store, key)
initHttpClient(store, router, undefined)
hookRoute(router, store, '会员管理')
app.use(router).mount('#app')
