/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText } from '@/util/models/admin/tableItem'
import { fromInput } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameNodeEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveUserActionRequest extends IValid {
  @vr({ required: true, min: 2, message: '权限名称不能为空' })
  action_name: string = ''

  @vr({ required: true, min: 1, message: '权限路由不能为空' })
  action_url: string = ''
}
export class EditUserActionRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '权限ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '权限名称不能为空' })
  action_name: string = ''

  @vr({ required: true, min: 1, message: '权限路由不能为空' })
  action_url: string = ''

  roles: Array<number> = []
}

export class QueryUserActionRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '权限名称' })
  action_name: string = ''
}
export class QueryUserActionResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '权限名称', e: { click: 'edit' } })
  action_name: string = ''

  @tableText({ header: '权限路由' })
  action_url: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export class GetUserActionResponse implements IdEntity {
  id: number = 0
  action_name: string = ''
  action_url: string = ''
  create_time: string = ''
  modify_time: string = ''
}

export class GetUserActionMapResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '权限名称' })
  action_name: string = ''

  @fromInput({ title: '权限路由' })
  action_url: string = ''

  create_time: string = ''
  modify_time: string = ''
  roles: Array<IdNameNodeEntity> = []
}

export class ActionApi {
  @execPostValid('/user/action/save')
  static async save(request: SaveUserActionRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/action/edit')
  static async edit(request: EditUserActionRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/action/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserActionResponse>('/user/action/query')
  static async query(request: QueryUserActionRequest): Promise<PageResponse<QueryUserActionResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserActionResponse>('/user/action/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserActionResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserActionMapResponse>('/user/action/getByMap', (resp) => resp ?? { id: 0, roles: [] })
  static async getByMap(request: ValidIdRequest): Promise<GetUserActionMapResponse> {
    throw new EmptyHttpError()
  }
}

export const ActionViewObj: ApiWinModel<QueryUserActionRequest, QueryUserActionResponse> =
  new ApiWinModel(() => new QueryUserActionRequest(), () => new QueryUserActionResponse())
export const editModel = () => new GetUserActionMapResponse()
export const getModelTitle = (r: GetUserActionMapResponse | GetUserActionMapResponse) => r.action_name
export const ActionOper: ISaveEditRequest<GetUserActionMapResponse, SaveUserActionRequest, EditUserActionRequest> = new ISaveEditRequest(
  {
    entity: () => new GetUserActionMapResponse(),
    save: () => new SaveUserActionRequest(),
    edit: () => new EditUserActionRequest()
  },
  {
    entity: ActionApi.getByMap,
    save: ActionApi.save,
    edit: ActionApi.edit,
    del: ActionApi.delete
  }
)
