/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableEnable, tableIdRow, tableText, tableIcon, tableButton, tableSlot } from '@/util/models/admin/tableItem'
import { IdNameEntity, PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveUserTagRequest extends IValid {
  @vr({ required: true, min: 2, message: '标签Key不能为空' })
  tag_key: string = ''

  @vr({ required: true, min: 2, message: '标签名称不能为空' })
  tag_name: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '过期类型不能为空' })
  expire_type: number = 0

  @vr({ max: 1024, message: '标签说明不能超过1000个字符' })
  tag_remark: string = ''
}

export class EditUserTagRequest implements IdEntity {
  @vr({ required: true, min: 0, message: '标签ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '标签Key不能为空' })
  tag_key: string = ''

  @vr({ required: true, min: 2, message: '标签名称不能为空' })
  tag_name: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '过期类型不能为空' })
  expire_type: number = 0

  @vr({ max: 1024, message: '标签说明不能超过1000个字符' })
  tag_remark: string = ''
}
export class QueryUserTagRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '标签名称' })
  tag_name: string = ''
}

export class QueryUserTagResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '标签', e: { click: 'edit' } })
  tag_key: string = ''

  @tableEditUrl({ header: '标签名称', e: { click: 'edit' } })
  tag_name: string = ''

  @tableSlot({ header: '过期类型' })
  expire_type: number = 0

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export class GetByIdUserTagResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '标签Key' })
  tag_key: string = ''

  @fromInput({ title: '标签名称' })
  tag_name: string = ''

  @fromSlot({ title: '激活追加' })
  expire_type: number = 0

  @areaInput({ title: '标签说明', min: 4, maxlength: 1000 })
  tag_remark: string = ''

  create_time: string = ''
  modify_time: string = ''
}

export class TagApi {
  @execPostValid('/user/tag/save')
  static async save(request: SaveUserTagRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/tag/edit')
  static async edit(request: EditUserTagRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/tag/delete')
  static async delete(request: IdEntity): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserTagResponse>('/user/tag/query')
  static async query(request: QueryUserTagRequest): Promise<PageResponse<QueryUserTagResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetByIdUserTagResponse>('/user/tag/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetByIdUserTagResponse> {
    throw new EmptyHttpError()
  }
}
export const TagExpireTyepList: IdNameEntity[] = [{ id: 0, name: '一年' }, { id: 1, name: '一月' }, { id: 3, name: '七天' }, { id: 2, name: '一天' }]
export const TagViewObj: ApiWinModel<QueryUserTagRequest, QueryUserTagResponse> =
  new ApiWinModel(() => new QueryUserTagRequest(), () => new QueryUserTagResponse())
export const editModel = () => new GetByIdUserTagResponse()
export const getModelTitle = (r: GetByIdUserTagResponse | QueryUserTagResponse) => r.tag_name
export const TagOper: ISaveEditRequest<GetByIdUserTagResponse, SaveUserTagRequest, EditUserTagRequest> = new ISaveEditRequest(
  {
    entity: () => new GetByIdUserTagResponse(),
    save: () => new SaveUserTagRequest(),
    edit: () => new EditUserTagRequest()
  },
  {
    entity: TagApi.getById,
    save: TagApi.save,
    edit: TagApi.edit,
    del: TagApi.delete
  }
)
