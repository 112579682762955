/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveUserMenuRequest extends IValid {
  app_id: number = 0

  parent: string = ''

  @vr({ required: true, min: 2, message: '菜单名称不能为空' })
  menu_name: string = ''

  menu_icon: string = ''

  menu_title: string = ''

  @vr({ max: 1024, message: '菜单说明不能超过1000个字符' })
  menu_desc: string = ''
}
export class EditUserMenuRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '菜单ID不能为空', type: 'number' })
  id: number = 0

  parent: string = ''

  app_id: number = 0

  @vr({ required: true, min: 2, message: '菜单名称不能为空' })
  menu_name: string = ''

  menu_icon: string = ''

  menu_title: string = ''

  @vr({ max: 1024, message: '菜单说明不能超过1000个字符' })
  menu_desc: string = ''
}

export class QueryUserMenuRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '菜单名称' })
  menu_name: string = ''

  app_id: number = 0
}
export class QueryUserMenuResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableIcon({ header: '图标', width: 50 })
  menu_icon: string = ''

  @tableEditUrl({ header: '菜单名称', e: { click: 'edit' } })
  menu_name: string = ''

  @tableEditUrl({ header: '菜单标题', e: { click: 'edit' } })
  menu_title: string = ''

  @tableText({ header: '父菜单' })
  parent: string = ''

  menu_desc: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export class GetUserMenuResponse implements IdEntity {
  id: number = 0
  @fromInput({ title: '菜单名称' })
  menu_name: string = ''

  @fromSlot({ title: '父菜单' })
  parent: string = ''

  @fromSlot({ title: '菜单图标' })
  menu_icon: string = ''

  @fromInput({ title: '菜单标题' })
  menu_title: string = ''

  @areaInput({ title: '菜单说明', min: 4, maxlength: 1000 })
  menu_desc: string = ''

  app_id: number = 0
  create_time: string = ''
  modify_time: string = ''
}

export class MenuApi {
  @execPostValid('/user/menu/save')
  static async save(request: SaveUserMenuRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/menu/edit')
  static async edit(request: EditUserMenuRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/menu/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserMenuResponse>('/user/menu/query')
  static async query(request: QueryUserMenuRequest): Promise<PageResponse<QueryUserMenuResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserMenuResponse>('/user/menu/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserMenuResponse> {
    throw new EmptyHttpError()
  }
}

export const MenuViewObj: ApiWinModel<QueryUserMenuRequest, QueryUserMenuResponse> =
  new ApiWinModel(() => new QueryUserMenuRequest(), () => new QueryUserMenuResponse())
export const editModel = () => new GetUserMenuResponse()
export const getModelTitle = (r: GetUserMenuResponse | QueryUserMenuResponse) => r.menu_name
export const MenuOper: ISaveEditRequest<GetUserMenuResponse, SaveUserMenuRequest, EditUserMenuRequest> = new ISaveEditRequest(
  {
    entity: () => new GetUserMenuResponse(),
    save: () => new SaveUserMenuRequest(),
    edit: () => new EditUserMenuRequest()
  },
  {
    entity: MenuApi.getById,
    save: MenuApi.save,
    edit: MenuApi.edit,
    del: MenuApi.delete
  }
)
