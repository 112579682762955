
import LoginRedirect from '@/views/LoginRedirect.vue'
import action from '@/views/userAction.vue'
import app from '@/views/userApp.vue'
import role from '@/views/userRole.vue'
import user from '@/views/userInfo.vue'
import group from '@/views/userGroup.vue'
import menu from '@/views/userMenu.vue'
import license from '@/views/userLicense.vue'
import console from '@/views/userConsole.vue'
import plugin from '@/views/userPlugin.vue'
import tag from '@/views/userTag.vue'
export default [
  {
    path: '/',
    component: LoginRedirect,
    name: '登录等待',
    meta: {
      hasRoot: true
    },
    children: []
  }, {
    path: '/index',
    component: console,
    name: '控制台',
    meta: {
      hasRoot: true,
      icon: 'el-icon-kongzhitaishouye',
      title: '用户首页',
      desc: ''
    },
    children: []
  }, {
    path: '/info',
    component: user,
    name: '用户信息',
    meta: {
      icon: 'el-icon-yonghuguanli',
      title: '用户信息',
      desc: ''
    },
    children: []
  }, {
    path: '/group',
    component: group,
    name: '用户分组',
    meta: {
      icon: 'el-icon-fenzu1',
      title: '用户分组',
      desc: ''
    },
    children: []
  }, {
    path: '/role',
    component: role,
    name: '用户角色',
    meta: {
      icon: 'el-icon-menu_role',
      title: '角色设置',
      desc: ''
    },
    children: []
  }, {
    path: '/app',
    component: app,
    name: '用户应用',
    meta: {
      icon: 'el-icon-app',
      title: 'App设置',
      desc: ''
    },
    children: []
  }, {
    path: '/menu',
    component: menu,
    name: '菜单设置',
    meta: {
      icon: 'el-icon-menu',
      title: '菜单设置',
      desc: ''
    },
    children: []
  }, {
    path: '/action',
    component: action,
    name: '用户权限',
    meta: {
      icon: 'el-icon-quanxian',
      title: '权限设置',
      desc: ''
    },
    children: []
  }, {
    path: '/tag',
    component: tag,
    name: '用户标签设置',
    meta: {
      icon: 'el-icon-navigation',
      title: '用户标签设置',
      desc: ''
    },
    children: []
  }, {
    path: '/license',
    component: license,
    name: '授权设置',
    meta: {
      icon: 'el-icon-license-',
      title: '授权设置',
      desc: ''
    },
    children: []
  }, {
    path: '/plugin',
    component: plugin,
    name: '插件控制',
    meta: {
      icon: 'el-icon-xiafapeizhi2',
      title: '插件控制',
      desc: ''
    },
    children: []
  }
]
