/* eslint-disable camelcase */
import { queryText, queryContent } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText } from '@/util/models/admin/tableItem'
import { IdNameEntity, PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import { fromInput, areaInput } from '@/util/models/admin/fromItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { queryPostValid, adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveUserGroupRequest extends IValid {
  @vr({ required: true, min: 2, message: '分组名称不能为空' })
  group_name: string = ''

  @vr({ max: 1024, message: '分组说明不能超过1000个字符' })
  group_desc: string = ''
}
export class EditUserGroupRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '分组ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '分组名称不能为空' })
  group_name: string = ''

  @vr({ max: 1024, message: '分组说明不能超过1000个字符' })
  group_desc: string = ''
}

export class QueryUserGroupRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '分组名称' })
  group_name: string = ''
}
export class QueryUserGroupResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '分组名称', e: { click: 'edit' } })
  group_name: string = ''

  @tableText({ header: '分组说明' })
  group_desc: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export class GetUserGroupResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '分组名称' })
  group_name: string = ''

  @areaInput({ title: '分组说明', min: 4, maxlength: 1000 })
  group_desc: string = ''

  create_time: string = ''
  modify_time: string = ''
}

export class GroupApi {
  @execPostValid('/user/group/save')
  static async save(request: SaveUserGroupRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/group/edit')
  static async edit(request: EditUserGroupRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/group/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserGroupResponse>('/user/group/query')
  static async query(request: QueryUserGroupRequest): Promise<PageResponse<QueryUserGroupResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetUserGroupResponse>('/user/group/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetUserGroupResponse> {
    throw new EmptyHttpError()
  }

  @queryPostValid<PageResponse<IdNameEntity>>('/user/group/getUserGroupList')
  static async getUserGroupList(request: ValidIdRequest): Promise<PageResponse<IdNameEntity>> {
    throw new EmptyHttpError()
  }
}

export const GroupViewObj: ApiWinModel<QueryUserGroupRequest, QueryUserGroupResponse> =
  new ApiWinModel(() => new QueryUserGroupRequest(), () => new QueryUserGroupResponse())
export const editModel = () => new GetUserGroupResponse()
export const getModelTitle = (r: GetUserGroupResponse | QueryUserGroupResponse) => r.group_name
export const GroupOper: ISaveEditRequest<GetUserGroupResponse, SaveUserGroupRequest, EditUserGroupRequest> = new ISaveEditRequest(
  {
    entity: () => new GetUserGroupResponse(),
    save: () => new SaveUserGroupRequest(),
    edit: () => new EditUserGroupRequest()
  },
  {
    entity: GroupApi.getById,
    save: GroupApi.save,
    edit: GroupApi.edit,
    del: GroupApi.delete
  }
)
