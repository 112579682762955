/* eslint-disable camelcase */

import { queryText, queryContent } from '@/util/models/admin/queryItem'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableButton } from '@/util/models/admin/tableItem'
import { IdNameEntity, PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import { areaInput, fromInput, fromSlot } from '@/util/models/admin/fromItem'
import { getIconClass } from '@util/utils/iconUtils'

export class SavePluginRequest extends IValid {
  @vr({ required: true, min: 2, message: '授权名称不能为空' })
  secret_name: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '分配用户不能为空' })
  user_id: number = 0

  @vr({ required: true, type: 'number', min: 0, message: '分配分组不能为空' })
  group_id: number = 0

  expired: string | undefined

  @vr({ required: true, type: 'number', min: 0, message: '分配通讯key不能为空' })
  client_key: number = 0
}

export class EditPluginRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '授权ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '授权名称不能为空' })
  secret_name: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '分配用户不能为空' })
  user_id: number = 0

  @vr({ required: true, type: 'number', min: 0, message: '分配分组不能为空' })
  group_id: number = 0

  expired: string | undefined

  client_key: number | undefined
}

export class QueryPluginResponse implements IdEntity {
  @tableButton({
    text: '设置内容',
    val: { width: 100, position: 'column' },
    style: { icon: getIconClass('el-icon-daoru'), type: 'info', size: 'small' },
    e: { click: 'changeItem' }
  })
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '授权名称', e: { click: 'edit' } })
  secret_name: string = ''

  @tableText({ header: '用户名称' })
  user_name: string = ''

  @tableText({ header: '分组名称' })
  group_name: string = ''

  @tableDate({ header: '过期时间' })
  expired: string | undefined

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class QueryPluginRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '授权名称' })
  secret_name: string = ''
}

export class GetPluginResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '授权名称' })
  secret_name: string = ''

  @fromSlot({ title: '分配用户' })
  user_id?: number

  user_name?: string

  @fromSlot({ title: '分配分组' })
  group_id?: number

  @fromSlot({ title: '过期时间' })
  expired: string | undefined

  @fromSlot({ title: '客户端密钥' })
  client_key: number | undefined
}
export interface DownloadResponse {
  secret: string
  content: string
}
export class GetPluginItemResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '授权名称' })
  secret_name: string = ''

  @fromSlot({ title: '内容类型' })
  key?: number

  @areaInput({ title: '内容', min: 10, max: 20 })
  content?: string
}

export class GetPluginItemRequest extends IValid implements IdEntity {
  id: number = 0

  @vr({ required: true, type: 'number', min: 0, message: '内容类型不能为空' })
  key: number = 0
}
export class SetPluginItemRequest extends IValid implements IdEntity {
  id: number = 0

  @vr({ required: true, type: 'number', min: 0, message: '内容类型不能为空' })
  key: number = 0

  @vr({ required: true, min: 0, message: '内容不能为空' })
  content: string = ''
}

export class PluginApi {
  @execPostValid('/user/plugin/save')
  static async save(request: SavePluginRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/plugin/edit')
  static async edit(request: EditPluginRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/plugin/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryPluginResponse>('/user/plugin/query')
  static async query(request: QueryPluginRequest): Promise<PageResponse<QueryPluginResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<string>('/user/plugin/allClient')
  static async allClient(request: any): Promise<PageResponse<string>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetPluginResponse>('/user/plugin/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetPluginResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetPluginResponse>('/user/plugin/download', s => s)
  static async download(request: ValidIdRequest): Promise<DownloadResponse> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetPluginItemResponse>('/user/plugin/getConfig', s => s)
  static async getConfig(request: GetPluginItemRequest): Promise<GetPluginItemResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/user/plugin/setConfig')
  static async setConfig(request: SetPluginItemRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<IdNameEntity>('/user/plugin/getConfigType')
  static async getConfigType(request: IdEntity): Promise<PageResponse<IdNameEntity>> {
    throw new EmptyHttpError()
  }
}

export const PluginViewObj: ApiWinModel<QueryPluginRequest, QueryPluginResponse> =
  new ApiWinModel(() => new QueryPluginRequest(), () => new QueryPluginResponse())
export const editModel = () => new GetPluginResponse()

export const editConfigModel = () => new GetPluginItemResponse()

export const getModelTitle = (r: GetPluginResponse | QueryPluginResponse | GetPluginItemResponse) => r.secret_name
export const PluginOper: ISaveEditRequest<GetPluginResponse, SavePluginRequest, EditPluginRequest> = new ISaveEditRequest(
  {
    entity: () => new GetPluginResponse(),
    save: () => new SavePluginRequest(),
    edit: () => new EditPluginRequest()
  },
  {
    entity: PluginApi.getById,
    save: PluginApi.save,
    edit: PluginApi.edit,
    del: PluginApi.delete
  }
)
