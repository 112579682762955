import 'reflect-metadata'

export enum IQueryType {
  QueryContentItem,
  TextQueryItem,
  DropdownQueryItem,
  FlagQueryItem,
  Slot
}

export interface IQueryItem {
  field: string
  sort: number
  type: IQueryType
  fieldText: string
}

export class QuerySlotItem implements IQueryItem {
  field: string
  sort: number
  fieldText: string
  type: IQueryType
  constructor(fieldName: string, title: string, index: number) {
    this.field = fieldName
    this.fieldText = title
    this.sort = index
    this.type = IQueryType.Slot
  }
}
export class QueryContentItem implements IQueryItem {
  field: string
  sort: number
  fieldText: string
  type: IQueryType
  value: any
  constructor(fieldName: string, val: any) {
    this.field = fieldName
    this.value = val
    this.sort = 0
    this.fieldText = ''
    this.type = IQueryType.QueryContentItem
  }
}
export class TextQueryItem implements IQueryItem {
  field: string
  fieldText: string
  type: IQueryType
  sort: number
  constructor(fieldName: string, text: string, index: number) {
    this.field = fieldName
    this.fieldText = text
    this.sort = index
    this.type = IQueryType.TextQueryItem
  }
}
export class DropdownQueryItem implements IQueryItem {
  field: string
  fieldText: string
  type: IQueryType
  sort: number
  values: Array<{ label: string, value: any }>
  constructor(fieldName: string, text: string, index: number, o: any) {
    this.field = fieldName
    this.fieldText = text
    this.sort = index
    this.values = []
    this.type = IQueryType.DropdownQueryItem
    for (const key in o) {
      this.values.push({ label: key, value: o[key] })
    }
  }
}
export class FlagQueryItem implements IQueryItem {
  field: string
  fieldText: string
  type: IQueryType
  sort: number
  values: Array<string>
  constructor(fieldName: string, text: string, index: number, trueStr: string = '是', falseStr: string = '否') {
    this.field = fieldName
    this.fieldText = text
    this.sort = index
    this.type = IQueryType.FlagQueryItem
    this.values = [trueStr, falseStr]
  }
}
const queryKey = 'queryFromRule'
export const queryText = (info?: { text?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(queryKey, target)) {
      Reflect.defineMetadata(queryKey, {}, target)
    }
    const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    rules[key.toString()] = new TextQueryItem(key.toString(), info?.text ?? key.toString(), index + 1)
    Reflect.defineMetadata(queryKey, rules, target)
  }
}
export const querySlot = (info?: { title?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(queryKey, target)) {
      Reflect.defineMetadata(queryKey, {}, target)
    }
    const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    rules[key.toString()] = new QuerySlotItem(key.toString(), info?.title ?? key.toString(), index + 1)
    Reflect.defineMetadata(queryKey, rules, target)
  }
}
export const queryDropdown = (o: object, info?: { text?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(queryKey, target)) {
      Reflect.defineMetadata(queryKey, {}, target)
    }
    const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    rules[key.toString()] = new DropdownQueryItem(key.toString(), info?.text ?? key.toString(), index + 1, o)
    Reflect.defineMetadata(queryKey, rules, target)
  }
}
export const queryFlag = (o?: Array<string> | undefined, info?: { text?: string | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (!Reflect.hasMetadata(queryKey, target)) {
      Reflect.defineMetadata(queryKey, {}, target)
    }
    const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, target)
    let index = 0
    if (rules !== undefined) {
      for (const key in rules) {
        index++
      }
    }
    rules[key.toString()] = new FlagQueryItem(key.toString(), info?.text ?? key.toString(), index + 1, o === undefined || o.length <= 0 ? '是' : o[0], o === undefined || o.length <= 1 ? '否' : o[1])
    Reflect.defineMetadata(queryKey, rules, target)
  }
}
export const queryContent = (o?: { changeRef?: boolean | undefined, loadQuery?: boolean | undefined, pageRef?: boolean | undefined } | undefined): PropertyDecorator => {
  return (target: any, key: string | symbol) => {
    if (o !== undefined) {
      if (!Reflect.hasMetadata(queryKey, target)) {
        Reflect.defineMetadata(queryKey, {}, target)
      }
      const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, target)
      const tmpO: any = o
      for (const key in tmpO) {
        rules['__' + key] = new QueryContentItem(key, tmpO[key])
      }
      Reflect.defineMetadata(queryKey, rules, target)
    }
  }
}
// export class QueryReqest {
//   @queryContent({ changeRef: true })
//   @queryText({ text: '名称' })
//   name: string = ''

//   @queryDropdown({ value: 1, value2: 2, all: undefined })
//   status: boolean | undefined = undefined

//   @queryFlag(['是', '否'])
//   flag: boolean = false
// }

export interface QueryRenderInfo {
  isAutoRef: boolean
  loadQuery: boolean
  formSize: string | undefined
  labelWidth: string | undefined
  fileds: Array<IQueryItem>
}

export function RenderQueryContent(o: any | Array<any>): QueryRenderInfo | undefined {
  if (o instanceof Array) {
    if (o != null && o.length > 0) {
      o = o[0]
    } else {
      return undefined
    }
  }
  if (!Reflect.hasMetadata(queryKey, o)) {
    return undefined
  }
  const rules: { [index: string]: IQueryItem } = Reflect.getMetadata(queryKey, o)
  if (rules === undefined) {
    return undefined
  }
  const ret: QueryRenderInfo = {
    isAutoRef: false,
    loadQuery: true,
    formSize: undefined,
    labelWidth: undefined,
    fileds: []
  }
  for (const key in rules) {
    const rule = rules[key]
    if (rule instanceof QueryContentItem) {
      if (rule.field === 'changeRef') {
        ret.isAutoRef = rule.value as boolean
      } else if (rule.field === 'loadQuery') {
        ret.loadQuery = rule.value as boolean
      } else if (rule.field === 'formSize') {
        ret.formSize = rule.value as string
      } else if (rule.field === 'labelWidth') {
        ret.labelWidth = rule.value as string
      }
    } else {
      ret.fileds.push(rule)
    }
  }
  ret.fileds.sort((a, b) => a.sort - b.sort)
  return ret
}
// const o = new QueryReqest()
// const ret = RenderQueryContent(o)
// console.log(ret)
