import { Ref, ref, UnwrapRef } from 'vue'

export const IntegerMAX = 2147483647
export const IntegerMIN = -2147483648

export interface IOptionItem {
  label: string
  value: string | number
}
export class QueryRefInfo {
  query: () => void = async() => {
    // def query
  }
}

export class DialogRefInfo {
  visible: Ref<boolean>
  refObj: (o?: any | undefined) => void
  error: (err: string, field?: string | undefined) => void
  constructor() {
    this.visible = ref(false)
    this.error = (a, b) => {
      // def error
    }
    this.refObj = (_) => {
      // def ref
    }
  }
}
export class TableOperationClz<T> {
  old: T | undefined
}
export class DialogAdaptRefInvoke {
  get: (resolve: (str: string) => void) => void
  constructor(get: (resolve: (str: string) => void) => void = DialogAdaptRefInvoke.defGet) {
    this.get = get
  }

  static defGet(resolve: (str: string) => void) {
    console.error('invoke default get')
    return undefined
  }
}

export class AdaptInvoke {
  get: () => string | undefined
  set: (content: string) => void
  constructor(get: () => string | undefined = AdaptInvoke.defGet, set: (content: string) => void = AdaptInvoke.defSet) {
    this.get = get
    this.set = set
  }

  static defGet(): undefined {
    console.error('invoke default get')
    return undefined
  }

  static defSet(content: string): void {
    console.error('invoke default Set')
    return undefined
  }
}

export interface IParamItem {
  Method: string
  Params: Array<string>
}
export interface IParamDialog {
  title?: string
  list?: Array<any>
  index: number
  param: IParamItem
}

export interface IEventItem {
  Key: number
  Value: number
  Percentage: number
}
export interface IEventItemEx {
  Key?: number
  Value: number
  Percentage: number
}
export class FliterGroupItem<T> {
  group: string = ''
  item: T
  constructor(group: string, item: T) {
    this.group = group
    this.item = item
  }
}
export interface ITreeEvent {
  getCheckedKeys: () => Array<string>
  setCheckedKeys: (arr: Array<number | string>) => void
  getNode: (key: string) => void
  remove: (key: string) => void
}
export interface IPopoverEvent extends HTMLElement {
  hide: () => void
  show: () => void
}

export interface ICarouselEvent extends HTMLElement {
  setActiveItem: (to: number | string) => void
}
export interface ElScrollbarEvent extends HTMLElement {
  setScrollTop: (to: number) => void
}
export interface ConfigOffsetSize {
  len: number,
  label: string,
  color?: string
}

export class ConfigOffset {
  offset: number = 0
  size?: number = undefined
}
export class ConfigStringOffset {
  count: number = 0
  offset: number = 0
  size: number = 0
}
export class ConfigChangeOffset {
  get: ConfigOffset = new ConfigOffset()
  set: ConfigOffset = new ConfigOffset()
}
export class ConfigChangeMaxOffset extends ConfigChangeOffset {
  max: number = 0
}
export function getConfigOffsetSize(): Array<ConfigOffsetSize> {
  return [
    { len: 0, label: 'none', color: '#f56c6c' },
    { len: 1, label: 'byte' },
    { len: 2, label: 'short' },
    { len: 4, label: 'integer' },
    { len: 8, label: 'long' }
  ]
}

export function getAdditionType(): { [index: number]: string } {
  return {
    1: '攻击',
    2: '魔法',
    3: '道术',
    4: '防御',
    5: '魔御',
    6: '攻击上限',
    7: '魔法上限',
    8: '道术上限',
    9: '防御上限',
    10: '魔御上限',
    11: '血量上限',
    12: '蓝量上限',
    13: '血量',
    14: '蓝量',
    15: '职业属性',
    16: '职业属性',
    17: '等级',
    18: '所有属性',
    19: '所有属性上限'
  }
}
export function getAdditionTypeArr() {
  const map = getAdditionType()
  const tmp: Array<IOptionItem> = []
  for (const item in map) {
    tmp.push({ label: map[item], value: parseInt(item, 0) })
  }
  return tmp
}

export function getElementType(): { [index: number]: string } {
  return {
    0: '元素属性0',
    1: '元素属性1',
    2: '元素属性2',
    3: '元素属性3',
    4: '元素属性4',
    5: '元素属性5',
    6: '元素属性6',
    7: '元素属性7',
    8: '元素属性8',
    9: '元素属性9',
    10: '元素属性10',
    11: '元素属性11',
    12: '元素属性12',
    13: '元素属性13',
    14: '元素属性14',
    15: '元素属性15',
    16: '元素属性16',
    17: '元素属性17',
    18: '元素属性18',
    19: '元素属性19'
  }
}
export function getElementTypeArr() {
  const map = getElementType()
  const tmp: Array<IOptionItem> = []
  for (const item in map) {
    tmp.push({ label: map[item], value: parseInt(item, 0) })
  }
  return tmp
}
