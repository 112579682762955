import { Module } from 'vuex'
import storageClientUtil from './storageClientUtil'
import { UserToken, LoginTokenResponse, UserBaseInfo } from './../models/TokenModels'

async function asyncLocalInfo(state: UserToken) {
  if (state === undefined) {
    state = new UserToken()
  }
  let localToken: UserToken | undefined
  if (state.token === undefined) {
    if (localToken === undefined) {
      localToken = await storageClientUtil.getToken()
    }

    if (localToken !== undefined && localToken.avatar !== undefined) {
      state.avatar = localToken.avatar
    }
    if (localToken !== undefined && localToken.user_name !== undefined) {
      state.user_name = localToken.user_name
    }
    if (localToken !== undefined && localToken.refresh !== undefined) {
      state.refresh = localToken.refresh
    }
    if (localToken !== undefined && localToken.remember !== undefined) {
      state.remember = localToken.remember
    }
    if (localToken !== undefined && localToken.token !== undefined) {
      state.token = localToken.token
    }
    const startTime = new Date().getTime() / 1000
    if (state.refresh !== undefined && startTime > state.refresh.expired) {
      state.refresh = undefined
    }
    if (state.token !== undefined && startTime > state.token.expired) {
      state.token = undefined
    }
  }
}
export const tokenModule: Module<UserToken, any> = {
  namespaced: true,
  state: {
    user_name: '',
    avatar: undefined,
    remember: undefined,
    token: undefined,
    refresh: undefined
  },
  getters: {
    async hashRemember(state: UserToken): Promise<boolean | undefined> {
      await asyncLocalInfo(state)
      return state?.remember ?? false
    },
    async hasToken(state: UserToken): Promise<boolean | undefined> {
      await asyncLocalInfo(state)
      if (state?.token?.token === undefined) {
        return false
      }
      if (state?.token?.expired === undefined) {
        return false
      }
      const startTime = new Date().getTime() / 1000
      if (startTime > state.token.expired) {
        return false
      }
      return true
    },
    async asyncToken(state: UserToken): Promise<boolean> {
      await asyncLocalInfo(state)
      if (state?.token?.token === undefined) {
        return false
      }
      if (state?.token?.expired === undefined) {
        return false
      }
      const startTime = new Date().getTime() / 1000
      if (startTime > state.token.expired) {
        return false
      }
      return true
    },
    async getUserInfo(state: UserToken): Promise<UserBaseInfo> {
      await asyncLocalInfo(state)
      return {
        user_name: state.user_name,
        avatar: state.avatar
      }
    },
    async getToken(state: UserToken): Promise<string | undefined> {
      await asyncLocalInfo(state)
      return state.token?.token
    },
    async getRefreshToken(state: UserToken) {
      await asyncLocalInfo(state)
      return state.refresh?.token
    }
  },
  mutations: {
    clearToken(state: UserToken) {
      storageClientUtil.clearToken()
      state.refresh = undefined
      state.token = undefined
      state.user_name = ''
    },
    async changeRefresh(state: UserToken, token: string) {
      await asyncLocalInfo(state)
      const startTime = new Date().getTime() / 1000
      state.refresh = {
        token: token,
        expired: startTime + 7200
      }
      storageClientUtil.updateToken(state)
    },
    async updateToken(state: UserToken, rep: LoginTokenResponse) {
      if (rep.access_token === undefined || rep.access_token === '') {
        throw new Error('get user token fail!')
      }
      if (rep.expired === undefined || rep.expired <= 0) {
        rep.expired = 7200
      }
      if (rep.user_name === undefined || rep.user_name === '') {
        throw new Error('get user name fail!')
      }
      if (rep.refresh_token === undefined || rep.refresh_token === '') {
        throw new Error('get user refresh token fail!')
      }
      const startTime = new Date().getTime() / 1000
      let hasUpdate = false
      if (rep.access_token !== undefined && rep.access_token !== '') {
        state.token = {
          token: rep.access_token,
          expired: startTime + rep.expired
        }
        hasUpdate = true
      }
      if (rep.refresh_token !== undefined && rep.refresh_token !== '') {
        state.refresh = {
          token: rep.refresh_token,
          // 2天
          expired: startTime + 172800
        }
        hasUpdate = true
      }
      if (rep.user_name !== undefined && rep.user_name !== '') {
        state.user_name = rep.user_name
        hasUpdate = true
      }
      if (rep.avatar !== undefined && rep.avatar !== '') {
        state.avatar = rep.avatar
        hasUpdate = true
      }
      if (rep.remember !== undefined) {
        state.remember = rep.remember
      }
      let localToken: UserToken | undefined
      try {
        localToken = await storageClientUtil.getTokenInfo()
      } catch {
        console.debug('not found token')
      }
      state.remember = localToken?.remember
      if (hasUpdate) {
        storageClientUtil.updateToken(state)
      }
    }
  },
  actions: {

  }
}
