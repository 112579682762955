export class CacheItem<T> {
  startTime = 0
  expires: number | undefined
  data!: T
  key: string
  constructor(key: string, value: T, expires?: number) {
    this.key = key
    this.data = value
    this.expires = expires
    this.startTime = 0
  }
}

export class LocalStorageUtils {
  static set<T>(key: string, value: T, expires?: number): void {
    const params: CacheItem<T | string> = new CacheItem(key, value, expires)
    if (expires) {
      params.startTime = new Date().getTime() / 1000
      localStorage.setItem(key, JSON.stringify(params))
    } else {
      const type = Object.prototype.toString.call(value)
      if (type === '[object Object]') {
        params.data = JSON.stringify(value)
      }
      if (type === '[object Array]') {
        params.data = JSON.stringify(value)
      }
      localStorage.setItem(key, JSON.stringify(params))
    }
  }

  static cache<T>(key: string): CacheItem<T> | undefined {
    let item: any = localStorage.getItem(key)

    if (item === undefined || item == null) {
      return undefined
    }
    try {
      item = JSON.parse(item)
    } catch (error) {
      item = localStorage.getItem(key)
    }

    if (item === undefined || item == null) {
      return undefined
    }

    if (!('key' in item)) {
      return undefined
    }

    const storageData: CacheItem<T> = item
    if (storageData.key !== key) {
      return undefined
    }
    const data = item.data
    try {
      item.data = JSON.parse(item.data)
    } catch (error) {
      item.data = data
    }
    if (storageData.startTime) {
      const date = new Date().getTime() / 1000
      if (date - item.startTime > item.expires) {
        localStorage.removeItem(item.key)
        return undefined
      } else {
        return item
      }
    } else {
      return item
    }
  }

  static get<T>(key: string): T | undefined {
    let item: any = localStorage.getItem(key)
    if (item === undefined || item == null) {
      return undefined
    }

    try {
      item = JSON.parse(item)
    } catch (error) {
      item = localStorage.getItem(key)
    }
    if (item === undefined || item == null) {
      return undefined
    }
    if (!('key' in item)) {
      return undefined
    }

    const storageData: CacheItem<T> = item
    if (storageData.key !== key) {
      return undefined
    }
    if (storageData.startTime) {
      const date = new Date().getTime() / 1000
      if (date - item.startTime > item.expires) {
        localStorage.removeItem(item.key)
        return undefined
      }
    }
    let result: T | undefined
    try {
      result = JSON.parse(item.data)
    } catch (error) {
      result = item.data
    }

    return result
  }

  static exist(key: string): boolean {
    let item: any = localStorage.getItem(key)
    if (item === undefined || item == null) {
      return false
    }

    try {
      item = JSON.parse(item)
    } catch (error) {
      item = localStorage.getItem(key)
    }
    if (item === undefined || item == null) {
      return false
    }
    if (!('key' in item)) {
      return false
    }

    const storageData: CacheItem<any> = item
    if (storageData.key !== key) {
      return false
    }
    if (storageData.startTime) {
      const date = new Date().getTime() / 1000
      if (date - item.startTime > item.expires) {
        localStorage.removeItem(item.key)
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  static remove(key: string): void {
    localStorage.removeItem(key)
  }

  static clear(): void {
    localStorage.clear()
  }
}
