/* eslint-disable camelcase */

export interface BaseRequest<T> {
  parameter: T | undefined
}
export const HttpLoginCode = 6

export class HttpError extends Error {
  code = 0
  constructor(code: number, msg: string) {
    super('code:' + code + ', msg:' + msg)
    this.code = code
  }
}
export class ServerError extends Error {
  code = 0
  constructor(code: number, msg: string) {
    super('code:' + code + ', msg:' + msg)
    this.code = code
  }
}
export class LogingError extends Error {
}
export class RefreshError extends Error {
}
export class TokenExpiredError extends Error {
}

export interface BaseResponse<T> {
  code: number
  message: string
  data: T
}
