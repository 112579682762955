export interface TokenCache {
  token: string
  expired: number
}
export interface UserBaseInfo {
  // eslint-disable-next-line camelcase
  user_name: string
  avatar?: string
}
export class UserToken implements UserBaseInfo {
  // eslint-disable-next-line camelcase
  user_name: string = ''
  avatar?: string | undefined
  remember?: boolean
  token?: TokenCache
  refresh?: TokenCache
}

export interface LoginTokenResponse extends UserBaseInfo {
  // eslint-disable-next-line camelcase
  access_token: string
  // eslint-disable-next-line camelcase
  refresh_token: string
  expired: number,
  // eslint-disable-next-line camelcase
  remember: boolean | undefined
}
